const quirkySentences = [
    "Counting the stars in a galaxy far, far away...",
    "Chasing unicorns through a rainbow-filled meadow...",
    "Swapping jokes with aliens on Neptune...",
    "Teaching penguins how to fly in Antarctica...",
    "Finding lost treasures in the Bermuda Triangle...",
    "Having a tea party with Bigfoot...",
    "Convincing dragons to share their hoard of gold...",
    "Playing hide and seek with leprechauns at the end of the rainbow...",
    "Dancing with robots on the moon...",
    "Solving mysteries with Sherlock Holmes in 19th century London...",
    "Searching for the fountain of youth with Indiana Jones...",
    "Exploring Atlantis with Poseidon as your guide...",
    "Having a rap battle with Shakespeare...",
    "Building sandcastles with mermaids under the sea...",
    "Attending a wizarding school and learning spells from Dumbledore...",
    "Competing in a pancake flipping contest with the Loch Ness Monster...",
    "Painting the sky with colors borrowed from Van Gogh...",
    "Creating a symphony with whales in the deep ocean...",
    "Learning to speak fluent dolphin...",
    "Planting a garden on Mars and watching it bloom..."
];

const rememberingQuotes = [
    "Those we love don't go away, they walk beside us every day.",
    "When someone you love becomes a memory, the memory becomes a treasure.",
    "In the hearts of those who love, the memory lives forever.",
    "The love we have for them never dies; it only grows stronger with time.",
    "Though they may be gone from our sight, they are never gone from our hearts.",
    "To live in the hearts we leave behind is not to die.",
    "Grief is the price we pay for love, but the memories we cherish are priceless.",
    "Even in the darkest moments, their light shines on in our memories.",
    "Their presence may be absent, but their spirit lingers on.",
    "We keep their memories alive by living our lives with love and purpose.",
    "Their legacy is not just what they did but how they made us feel.",
    "Those we hold closest to our hearts never truly leave us; they live on in the kindness they shared and the love they brought into our lives.",
    "The ones we love may leave us physically, but their love and lessons remain eternally.",
    "The pain of loss is the price we pay for the privilege of loving deeply.",
    "We honor their memory by living our lives with the same love and passion they showed us.",
    "As long as we remember them, they will always be with us.",
    "Their absence may be felt, but their love is forever present.",
    "Even in death, their love continues to guide us.",
    "Their memory is a silent echo of the love they once gave us.",
    "In the garden of memories, they bloom forever.",
    "The ones we love live on in the stories we tell and the memories we share.",
    "Their spirit dances with ours in the realm of memory.",
    "The bonds of love are not broken by death; they transcend it.",
    "Their legacy is written not in ink but in the hearts of those they touched.",
    "The memories we cherish are the bridges that connect us to the ones we've lost.",
    "Their absence may be felt, but their love remains a constant presence.",
    "In the tapestry of life, their memory is a thread that binds us together.",
    "The love they left behind is a beacon that guides us through the darkness of grief."
];
  
  

const didYouKnowSentences = [
    "Did you know? The shortest war in history lasted only 38 minutes, between Britain and Zanzibar in 1896.",
    "Did you know? Honey never spoils. Archaeologists have found pots of honey in ancient Egyptian tombs that are over 3,000 years old and still perfectly edible.",
    "Did you know? The Eiffel Tower can be 15 cm taller during the summer due to thermal expansion.",
    "Did you know? There are more possible iterations of a game of chess than there are atoms in the known universe.",
    "Did you know? Bananas are berries, but strawberries aren't.",
    "Did you know? The shortest war in history lasted only 38 minutes, between Britain and Zanzibar in 1896.",
    "Did you know? Mount Everest grows about a quarter of an inch taller every year.",
    "Did you know? Octopuses have three hearts.",
    "Did you know? The average person will spend six months of their life waiting for red lights to turn green.",
    "Did you know? An octopus can squeeze through a hole the size of a coin because it doesn't have any bones.",
    "Did you know? Humans share 50% of their DNA with bananas.",
    "Did you know? There's a planet where it rains glass sideways, with winds blowing up to 7,000 kilometers per hour.",
    "Did you know? A day on Venus is longer than a year on Venus.",
    "Did you know? Wombat poop is cube-shaped.",
    "Did you know? The dot over the letter 'i' is called a tittle.",
    "Did you know? Bees can recognize human faces.",
    "Did you know? The first oranges weren't orange. They were green.",
    "Did you know? The longest time between two twins being born is 87 days.",
    "Did you know? The unicorn is the national animal of Scotland.",
    "Did you know? The shortest war in history was between Britain and Zanzibar on August 27, 1896. Zanzibar surrendered after 38 minutes.",
    "Did you know? There are more possible iterations of a game of chess than there are atoms in the known universe.",
    "Did you know? The world's largest snowflake was 15 inches wide and 8 inches thick.",
    "Did you know? The Hawaiian alphabet has only 13 letters.",
    "Did you know? The Great Wall of China is not visible from space without aid.",
    "Did you know? Starfish don't have brains.",
    "Did you know? The original name for the search engine Google was Backrub.",
    "Did you know? The total weight of all the ants on Earth is about the same as the weight of all the humans on Earth.",
    "Did you know? There are more possible iterations of a game of chess than there are atoms in the known universe.",
    "Did you know? The electric chair was invented by a dentist.",
    "Did you know? A single cloud can weigh more than 1 million pounds.",
];

const mortalityQuotesSoft = [
    "In the end, each of us becomes a story whispered on the breeze.",
    "Death is the gentle embrace awaiting us all.",
    "Mortality gently reminds us of life's delicate beauty.",
    "Life is fleeting, but the echoes of our love resonate eternally.",
    "From stardust we emerged, to stardust we shall return, forever intertwined with the cosmos.",
    "Our mortality is the gentle nudge urging us to savor every precious moment.",
    "In the quiet whispers of mortality, we find the courage to embrace life's uncertainties.",
    "Mortality teaches us to treasure the fleeting moments that make life extraordinary.",
    "Death is not the end, but a transition into the infinite embrace of the universe.",
    "In the face of mortality, every heartbeat becomes a symphony of gratitude.",
    "We are but fleeting shadows dancing in the sunlight of eternity.",
    "Mortality is the tender brushstroke that gives depth to the canvas of life.",
    "Our mortality invites us to find meaning in the quiet spaces between the chaos.",
    "In the presence of mortality, love becomes the gentle melody that lingers in our hearts.",
    "Our mortality is the gentle reminder to cultivate compassion and kindness in our brief time here.",
    "Mortality is the delicate thread that weaves the tapestry of our existence.",
    "From life's first whispers to its final sigh, mortality guides our journey with quiet grace.",
    "Mortality is the gentle current that guides us along the river of life.",
    "In the embrace of mortality, we find solace in the shared human experience.",
    "Mortality whispers secrets of eternity, inviting us to ponder the mysteries of existence.",
    "Life's story finds its richness in the gentle ebb and flow of mortality.",
    "In the tender embrace of mortality, we discover the beauty of impermanence.",
    "In the gentle rhythm of mortality, every moment becomes a sacred dance.",
    "From mortality blossoms the fragile petals of wisdom, each moment a precious gift.",
    "Our mortality is the gentle caress that awakens us to the majesty of life's unfolding.",
    "In the quietude of mortality, we find the courage to embrace the unknown.",
    "Mortality is not the end, but the beginning of our journey into the vast unknown.",
    "Life's symphony finds its harmony in the gentle melodies of mortality.",
    "In the poetry of mortality, we find the beauty of our shared humanity.",
    "Mortality is the gentle whisper that invites us to live our lives with intention and grace."
  ];
  
  
  const randomQuote = () => rememberingQuotes[Math.floor(Math.random() * rememberingQuotes.length)];
  const moralityQuote = () => mortalityQuotesSoft[Math.floor(Math.random() * mortalityQuotesSoft.length)];
  const randomFact = () => didYouKnowSentences[Math.floor(Math.random() * didYouKnowSentences.length)];
  const randomSentence = () => quirkySentences[Math.floor(Math.random() * quirkySentences.length)];

  export {randomQuote, moralityQuote, randomFact, randomSentence}