import React from 'react';
import './personCardLrg.css';
import { noAuthGet, noAuthPost, postAuthData, postDeleleAuthData } from '../../../../utils/axiosCalls';
import QRCode from 'qrcode';
import {config} from '../../../../config';
import compressAndSendImage from '../../../../utils/compressor.js';
import UploadFile from './utils/fileUpload/fileupload.js';
import CarouselHolder from './utils/imageCarousel/carousel.js';
import noProfilePicAvatar from "../../../../images/blankAvatar.png"
import { Loader } from '../../../../utils/loader.js';
import { InfoModal } from '../../../Modal/Modal.js';
import { FaMapMarkedAlt } from "react-icons/fa";

import { convertDate } from '../../../../utils/singularUtils.js';
import addToolTip from '../../../../utils/tooltip/addTooltip.js';
import {MapModal} from '../../../Modal/Modal.js'



export default class PersonCardLrg extends React.Component {
    constructor(props) {
    super(props);

    // using props.props to access data

    this.props = props
    this.urlPointer = props.props[0].images ? props.props[0].images : null;
    this.data = props.props[0];
    this.images = [];
    this.hasImage = false;
    this.b64 = []
    this.docs = [];
    this.setRan = false;
    this.exit = props.callback;
    this.callType = props.callType;
    this.onbegin = props.onbegin || null;
    this.user = props.user || false;
    this.imageUpload = false;
    this.carouselImages = [];
    this.carouselBool = false;
    this.state = {
        carousel: true,
        urls: [],
        count: null,
        loading: false,
        showModal: false,
        modalText: "",
        modalTitle: "",
        showMap: false
    }

    this.setImages = this.setImages.bind(this);
    this.insertImageTag = this.insertImageTag.bind(this);
    this.qr = this.qr.bind(this);
    this.setDocs = this.setDocs.bind(this);
    this.UploadAction = this.UploadAction.bind(this);
    this.UploadImage = this.UploadImage.bind(this);
    // this.viewOptionContainer = this.viewOptionContainer.bind(this);
}

socialMediaIconSetter(id, url) {
    let greyedOut = false
    if(!url || url === undefined) greyedOut = true;
    
    let icons = ["fbIcon", "instaIcon", "twitterIcon", "youtubeIcon"];
    let icon = icons[id];
    greyedOut = greyedOut ? "greyScale" : false;

    let clickHandler = () => {
        window.open(!greyedOut ? url : '', '_blank');
    }

    return <div className={`socialMediaIcon tooltip ${icon} ${greyedOut}`} onClick={!greyedOut ? clickHandler : undefined}><span className='tooltiptext tooltiptext-Bottom'>{!greyedOut ? url : "None"}</span></div>
}

componentDidMount() {
    if(!this.setRan) {
        this.setImages()
        this.setDocs()
        if(this.callType !== "qr" && this.data.creator === this.user.email) {
            noAuthGet(`/scan/count/${this.data._id}`, (res) => {
                if(res.data.count !== "No QR Count") {
                    this.setState({count: res.data.count})
                }
            })
        }
        this.setRan = true;
        if(this.onbegin) this.onbegin()
    }
}

insertImageTag(url, i=null) {
    
    // must add functionality so card can only be clicked once and when another is clicked previous images are removed from view.
    let imagediv = document.querySelector('.images');
    // removes all img tags in any imge class divs and sets display to none
    let imgContainer = document.createElement('div');

    let imgTag = imgContainer;
    imgContainer.classList.add(`imgContainer-${i}`)
    imgContainer.classList.add('imgContainer')
    imgContainer.classList.add('imgContainerSizing')
    imgTag.classList.add(`imgTag-${i}`)

    imgTag.style.backgroundImage = `url('${url}')`

    // if user is creator of the LO then show delete button
    if(this.user.email === this.data.creator) {

        let setProfilePic = document.createElement('div');

        setProfilePic.textContent = 'P';
        setProfilePic.classList.add(`profilePic-${i}`)
        setProfilePic.classList.add('LoImgBtns')

        // setProfilePic.style.left = '10px';
        setProfilePic = addToolTip(setProfilePic, "Set Profile Pic", "Right")

        setProfilePic.onclick = (e) => {
            let choice = window.confirm('Do you want to set this image as the profile picture for your loved one? ')
            if (choice) {
                // add axios post to enpoint with image and maybe use bade 64 encoding to find correct image to remove
                let name = this.data.name;
                let index = e.target.classList[0].split('-')[1]
                let images = this.data.images.split(',')
                let profilePic = images[index];
                if(this.data.profilePic && this.data.profilePic === profilePic) {
                    let btnContainer = document.querySelector('.uploadImageCont');
                    let error = document.createElement('p');

                    error.textContent = 'This is already the profile picture.';
                    error.style.color = 'white';
                    error.style.background = 'red';
                    error.style.padding = '1rem';
                    error.style.marginLeft = '1rem';
                    btnContainer.append(error);
                    setTimeout(() => {
                        btnContainer.removeChild(error);
                        btnContainer.style.background = 'none';

                    }, 2000)
                    e.stopPropagation();
                    
                    return null
                }               
                // console.log(elToRemove)
                postDeleleAuthData('/api/images/set/profilepic', {name, profilePic}, null, (res) => {
                    let btnContainer = document.querySelector('.uploadImageCont');
                    let error = document.createElement('p');
                    
                    if(res.data.status === 'success') {
                        let pp = document.querySelector('.profilePicElContainer');
                        pp.style.backgroundImage = `url(https://app.a-lo.life/public/${images[index]})`
                        error.textContent = 'Successfully changed the profile picture';
                        error.style.color = 'white';
                        error.style.padding = '1rem';
                        error.style.marginLeft = '1rem';
                        error.style.background = 'green';
                        btnContainer.append(error);

                        setTimeout(() => {
                            btnContainer.removeChild(error);
                            btnContainer.style.background = 'none';

                        }, 2000)
                    } else {
                        console.log(res.data.message)
                        error.textContent = 'Error changing profile picture';
                        error.style.color = 'white';
                        error.style.background = 'red';
                        error.style.padding = '1rem';
                        error.style.marginLeft = '1rem';
                        btnContainer.append(error);
                        setTimeout(() => {
                            btnContainer.removeChild(error);
                            btnContainer.style.background = 'none';

                        }, 2000)
                    }
                })
            }
            e.stopPropagation();
        }

        let exitBtnCont = document.createElement('div');
        exitBtnCont.style.display = 'flex';
        exitBtnCont.style.width = '80%';
        exitBtnCont.style.justifyContent = 'space-between';
        exitBtnCont.style.padding = "5px 10px";
    
        let exitBtn = document.createElement('p');
        exitBtn.classList.add(`imgExit-${i}`)
        exitBtn.classList.add(`imgExit`)
        exitBtn.classList.add(`LoImgBtns`)
        exitBtn.textContent = 'X';
        exitBtn = addToolTip(exitBtn, "Delete", "Left")

        

        exitBtn.onclick = (e) => {
            let choice = window.confirm('Are you sure you want to delete this image? ')
            if (choice) {
                // add axios post to enpoint with image and maybe use bade 64 encoding to find correct image to remove
                let name = this.data.name;
                let index = e.target.classList[0].split('-')[1]
                let base64String = this.b64[index]
                
                postDeleleAuthData('/api/images/delete', {name, base64String, url}, null, (res) => {
                    let btnContainer = document.querySelector('.uploadImageCont');
                    let error = document.createElement('p');
                    
                    if(res.data.status === 'success') {
                        error.textContent = res.data.message;
                        error.style.color = 'white';
                        error.style.padding = '1rem';
                        error.style.marginLeft = '1rem';
                        error.style.background = 'green';
                        btnContainer.append(error);
                        let elToRemove = document.querySelector(`.imgContainer-${index}`)
                        let imagediv = document.querySelector('.images');
                        imagediv.removeChild(elToRemove)

                        setTimeout(() => {
                            btnContainer.removeChild(error);
                            btnContainer.style.background = 'none';

                        }, 2000)
                    } else {
                        console.log(res.data.message)
                        error.textContent = res.data.message;
                        error.style.color = 'white';
                        error.style.background = 'red';
                        btnContainer.append(error);
                        setTimeout(() => {
                            btnContainer.removeChild(error);
                            btnContainer.style.background = 'none';

                        }, 2000)
                    }
                })
            }
            e.stopPropagation();
        }

        let addHoverBorder = (e, color, enter=true) => {
            let imgNum = `.imgContainer-${e.target.classList[0].split('-')[1]}`
            let img = document.querySelector(imgNum)

            if(color !== null) img.style.border = `2px solid ${color}`;
            else img.style.border = "none";

            if(color === 'red' || color === 'orange') {
                e.target.style.background = color;
                e.target.style.color = 'white';

            }
            if(enter === false) {
                e.target.style.background = "white";
                e.target.style.color = 'black';
                e.target.style.border = 'none';
            }
        }
        setProfilePic.onmouseenter = (e) => addHoverBorder(e, 'orange')
        setProfilePic.onmouseleave = (e) => addHoverBorder(e, null, false)

        exitBtn.onmouseenter = (e) => addHoverBorder(e, 'red')
        exitBtn.onmouseleave = (e) => addHoverBorder(e, null, false)
        exitBtnCont.appendChild(setProfilePic);
        exitBtnCont.appendChild(exitBtn);
        imgContainer.appendChild(exitBtnCont);
    }
    

    imgTag.onclick = () => {
        let win = window.open(url, '_blank');
        win.focus()
    }
    
    
    // imgContainer.appendChild(imgTag);
    imagediv.appendChild(imgContainer);
    imagediv.style.display = 'flex';
    
}

fileDeleteHander(e, fn) {
    let fileName = fn;
    
    let lo = this.data.name;
    // console.log('deleting', e.target, fileName, lo)
    let parent = e.target.parentElement.parentElement;
    let child = e.target.parentElement
    let exitBtn = child.children[0]


    let choice = window.confirm('Are you sure you want to delete this file? ')
    if (choice) {

        postDeleleAuthData('/api/fremove', {fileName, lo}, null, (res) => {
            // parent.removeChild(child)
            // console.log(res.data.status);
            let message;
            message = document.createElement('p');
            message.textContent = res.data.status === "failed" ? "Failed" : "Success";
            message.style.padding = "0px 5px";
            if(res.data.status !== 'success') {
                console.log(res.data.message, parent, child)
                message.style.color = 'red'
                
            } else {
                message.style.color = 'green'
                child.innerHTML = '';
                child.style.display = 'none';
                // gets current delete doc, checks this.data.docs and removes it so it doesnt re-render if dom re-renders
                this.data.documents = this.data.documents.split(',').filter(doc => !doc.includes(e.target.parentElement.children[2].textContent)).join(',');

            }
            child.innerHTML = ""
            child.appendChild(message);
            child.appendChild(exitBtn)

            setTimeout(() => {
                child.removeChild(message)
                if(res.data.status !== 'success') {
                    // parent.appendChild(child)
                    
                } else {
                    
                }
            }, 2000)
        })
    }
}

createDocsElements(el, link=null) {
    let container = document.createElement('div');
    container.classList.add('fileDivContainer');
    
    let exitDiv = document.createElement('div');
    exitDiv.classList.add('fileExitDiv')
    let exitBtn = document.createElement('p');
    let iconImg = document.createElement('div');
    iconImg.classList.add('fileIconDiv')

    let fileName_ptag = document.createElement("P");
    fileName_ptag.style.width = '80%';
    fileName_ptag.style.textAlign = 'center';

    exitBtn.classList.add('file_p-tag_Exit')
    
    exitBtn.onmouseenter = () => {
        // let container = document.querySelector('.fileDivContainer')
        fileName_ptag.style.color = 'red';
        container.style.borderBottom = '1px solid red';
    }
    exitBtn.onmouseleave = () => {
        fileName_ptag.style.color = 'black';
        container.style.borderBottom = '1px solid orange';
        fileName_ptag.style.color = 'orange';
    }
    container.onmouseenter = () => {
        fileName_ptag.style.color = 'orange';
        container.style.borderBottom = '1px solid orange';
    }
    container.onmouseleave = () => {
        fileName_ptag.style.color = 'black';
        container.style.border = 'none';
    }
    
    exitBtn.textContent = 'X';
    // filename comes in as email_LOname_fileName 
    // if filename has underscores it affects this so added code to remove the first 2 (email, LOname)
    // and concat the rest as filename
    let fileName = el.split('/')[3]
    if(!fileName) return

    exitBtn.onclick = (e) => {
        this.fileDeleteHander(e, fileName);
        e.stopPropagation()
    }
    
    let fileType = fileName.split('.')[1]
    switch (fileType) {
        case 'docx':
            iconImg.classList.add('wordIcon')
            break;
        case 'txt':
            iconImg.classList.add('textIcon')

            break;
        case 'pdf':
            iconImg.classList.add('pdfIcon')

            break;
        default:
            break;
    }
    
    let fileNameShrinker = (fileName) => fileName.length > 25 ? fileName.substring(0,23) + '...' : fileName

    fileName_ptag.textContent = fileNameShrinker(fileName);
    fileName_ptag.style.cursor = 'pointer';
    fileName_ptag.classList.add('fileElement');
    // fileName_ptag.classList.add('tooltip');
    // let tip = document.createElement('span')
    // tip.classList.add('tooltiptext');

    // tip.textContent = fileName;
    // fileName_ptag.appendChild(tip)
    if(fileName.length > 25) {
        fileName_ptag = addToolTip(fileName_ptag, fileName)
    }

    container.addEventListener('click', () => {
        // Opens a new window that holds the text files 
        let openLink = this.data.documents.split(',').filter(link => link.includes(fileName))
        
        window.open(`${config.BACKEND_URL}/public/${link === null ? openLink : link}` , '_blank');
    })
    exitDiv.appendChild(exitBtn)
    container.appendChild(exitDiv)
    container.appendChild(iconImg)
    container.appendChild(fileName_ptag)
    try {
        document.querySelector('.Files').appendChild(container)
        
    } catch (error) {
        console.log('error', error.message)
    }
}

setDocs(link=null) {
    if(link) this.data.documents = this.data.documents[0] ? this.data.documents + ',' + link : link;

    if(!this.data.documents) {
        if(document.querySelector('.fileTitle')) {
            if(this.callType === "qr") document.querySelector('.fileTitle').style.display = 'none';
            document.querySelector('#filesDiv').style.display = 'none';

        }
    } else {
        if(document.querySelector('.fileTitle')) document.querySelector('.fileTitle').textContent = 'Files';
        document.querySelector('.Files').innerHTML = ''
        let docs = this.data.documents.split(',');

        if(docs[0]) {
            docs.forEach(el => {
                // console.log(el)
                el !== "" && this.createDocsElements(el)
            })
        }
    }
} 

setImages(grid=false) {
    try {
        let pp = document.querySelector('.profilePicElContainer');
        let carouselBtn = document.querySelector('.carouselBtn');
        let gridBtn = document.querySelector('.gridBtn');
        let imagediv = document.querySelector('.images');
        pp.style.display = 'block';

        let isQRTyppe = this.callType !== "qr"
        imagediv.style.display= "none"
        
        let imageUrls = this.data.images ? this.data.images.split(',').filter(s => s !== '') : null
        if(imageUrls !== null && imageUrls[0]) {
            let setupUrls = imageUrls.map(url => `${config.BACKEND_URL}/public/${url}`)
            if(this.data.profilePic !== "") pp.style.backgroundImage = `url(https://app.a-lo.life/public/${this.data.profilePic})`
            else {
                pp.style.backgroundImage = `url(https://app.a-lo.life/public/${setupUrls[0]})`
            }

            if(!grid || isQRTyppe) {

                if(!isQRTyppe) {
                    gridBtn.style.background = 'orange';
                    carouselBtn.style.background = 'none';
                }
                
                imagediv.style.display= "flex"
                imagediv.innerHTML === "" && setupUrls.map((url, i) => this.insertImageTag(url, i)) 
            } else if (grid) {
                if(!isQRTyppe) {
                    carouselBtn.style.background = 'orange';
                    gridBtn.style.background = 'none';

                }

                imagediv.innerHTML = '';
                this.setState({
                    carousel: true,
                    urls: setupUrls
                })
            }
        } else {
            pp.style.backgroundImage = `url(${noProfilePicAvatar})`
            if(this.callType === "qr") {
                document.querySelector('.imagesTitle').style.display = 'none';
                document.querySelector(".imageOptionContainer").style.display = "none";
            }
        }
    } catch (error) {
        console.log(error)
    }
}

qr() {
    document.querySelector(".qrBtn").style.disabled = "true";
    let backendURL = config.BACKEND_URL
    var img = document.getElementById('qrImage');
    if(img.src) {
        return 
    }

    let doc = document.querySelector('.qrCodeBtn')
    let pEl = document.createElement('p');

    let QRBackendRoute = `${backendURL}/scan/createQR`;
    
    noAuthPost(QRBackendRoute, {id: this.data._id}, (res) => {
        // console.log(res)
        if(res.statusText === "OK" || res.data.QRUrl) {
            let QRurl = res.data.QRUrl
            // console.log(QRurl)

            pEl.textContent = 'Click the QR to view the loved ones main page.'

            var opts = {
                errorCorrectionLevel: 'H',
                type: 'image/jpeg',
                quality: 0.8,
                margin: 1,
                color: {
                    light:"#ffffff",
                    dark:"#000000"
                }
            }

            QRCode.toDataURL(QRurl, opts, function (err, url) {
                if (err) throw err
            
                img.src = url;
                img.onclick = () => {window.open(QRurl, '_blank')}
                img.style.display = 'block';

            })
            let index = Object.keys(doc.children).length -1;
            doc.children[index].textContent !== 'Click the QR to view the loved ones main page.' && doc.appendChild(pEl)
        } else {
            console.log("There was an error requesting the qr url")
        }
    });

    
}

UploadAction() {
    if(this.state.loading) return
    let imagediv = document.querySelector('.images');
    imagediv.innerHTML = ""

    this.setState({loading: true})
    let input = document.querySelector('.cardImgUploader');
    const fd = new FormData();
    // Iterate over all selected files
    Array.from(input.files).forEach((file, i) => {
        fd.append(`image${i}`, file);
    });

    postAuthData(`/api/images/${this.data.name}/${this.data._id}`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        }}, (res) => {
            // console.log(res)
            this.data.images = res.data.pointer.join(',') + "," + this.data.images
            this.setImages()
            let btnEl = document.querySelector('.uploadImageCont');
            btnEl.removeChild(document.querySelector('.imgInputContainer'))
            btnEl.removeChild(document.querySelector('.imgUploadExit'))
            this.setState({loading: false})
            let readyBtn = document.querySelector('.uploadImageBtnReady');
            let btn = document.querySelector('.uploadImageBtn');
            readyBtn.style.display = 'none';
            btn.style.display = "flex";
            this.imageUpload = !this.imageUpload
    })
}

UploadImage() {
    // must set check to add image limit for loved ones
    let currentState = this.imageUpload
    this.imageUpload = !this.imageUpload

    let inputChangeHandler = (e) => {
        
        let readyBtn = document.querySelector('.uploadImageBtnReady');
        let btn = document.querySelector('.uploadImageBtn');
        let btnContainer = document.querySelector('.uploadImageCont');
        let exitBtn = document.createElement('p');
        
        exitBtn.classList.add(`imgUploadExit`)
        
        btn.style.display = 'none';
        readyBtn.style.display = "flex";
        exitBtn.textContent = 'X';
        
        exitBtn.onclick = () => {

            let readyBtn = document.querySelector('.uploadImageBtnReady');
            let btn = document.querySelector('.uploadImageBtn');
            readyBtn.style.display = 'none';
            btn.style.display = "flex";
            btnContainer.removeChild(document.querySelector('.imgUploadExit')) 
            btnContainer.removeChild(document.querySelector('.imgInputContainer')) 
            this.imageUpload = !this.imageUpload
        }
        let alreadyContainsExit = document.querySelector('.imgUploadExit')
        if(alreadyContainsExit === null) btnContainer.append(exitBtn)
    } 

    let createInputAndLabel = () => {
        let container = document.createElement('div');
        let inputEl = document.createElement('input');
        inputEl.onchange = (e) => inputChangeHandler(e);
        container.classList.add('imgInputContainer');
        inputEl.classList.add('cardImgUploader');
        inputEl.setAttribute('type', 'file')
        inputEl.setAttribute('accept', "image/jpeg,image/gif,image/png,image/x-eps")
        inputEl.setAttribute('multiple', 'true')
        container.append(inputEl)
        return container
    }

    let inputContainer = createInputAndLabel()
    let btnEl = document.querySelector('.uploadImageCont');
    currentState ? btnEl.removeChild(document.querySelector('.imgInputContainer')) : btnEl.append(inputContainer)
}

render() {
    let capStr = (str) => str[0].toUpperCase() + str.split('').map((char, i) => i !== 0 ? char : null).join('')
    let splitAndCapitalise = (str) => str.includes(' ') ? str.split(' ').map(name => name && capStr(name)).join(' ') : capStr(str);

    let bioElement = () => (
        <div className="bioElement">
            <div className='profilePicElContainer' />
            <div className="bioElementChild">
                <p className='bioContent'>{capStr(this.data.bio).replace('<br/>', '\r\n')}</p>
            </div>
        </div>
    )

    let locationProcessor =(loc) => {
        return loc.split("#_#")
    }  

    return (
        <>
        {this.state.loading && <Loader />}
        {this.state.showModal ? <InfoModal title={this.state.modalTitle} text={this.state.modalText} callback={() => {this.setState({showModal: false})}}/> :
        <div className={`${this.callType === "qr" ? 'pCardLrgQr' : 'pCardLrg'} `} >
            {this.callType !== "qr" && <div className="exit" onClick={() => {this.exit()}}><p className='personCardLergExitBtn'>X</p></div>}
                <p className="personNameLrg">{splitAndCapitalise(this.data.name.toLowerCase())}</p>
                <br/>
                <br/>
                {this.data.bio && bioElement()}
                <div className=''>
                    {((this.state.count || this.data.comments) && this.callType !== "qr")  && <>
                        <p className='sectionTitle'> Loved One Data</p>
                        <div className='sectionBody'>
                            <div className="flexRowLrg infoMarg">
                                {this.state.count && <p><span>QR Scan Count:</span> {this.state.count}</p>}
                                {(this.data.comments !== undefined && this.data.comments.split(",")[0]) && <p><span>Comment Count:</span> {this.data.comments.split(',').length}</p>}
                            </div>
                        </div>
                    </>}
                    <p className='sectionTitle'>Information</p>
                    <br/>
                    <div className='sectionBody'>
                        <div className="flexRowLrg infoMarg">
                            <p><span>Born:</span> {convertDate(this.data.dob)}</p>
                            <p><span>Died:</span> {convertDate(this.data.dod)}</p>
                        </div>
                        <div className="flexRowLrg infoMarg">
                            <p><span>Born In:</span> {locationProcessor(this.data.birthPlace)[0]}</p>
                            <p><span>Buried In:</span> {locationProcessor(this.data.burialPlace)[0]}</p>
                        </div>
                            {locationProcessor(this.data.birthPlace)[2] && <div className="MapButtonContainer_personCard">
                                <button
                                    className="MapButtons aloBtn"
                                    style={{width: "auto"}}
                                    onClick={(e) => {this.setState({showMap: !this.state.showMap})}}
                                >
                                    <p style={{marginRight: "1rem", display: "flex", alignItems:"center"}}>View Map</p>
                                    <FaMapMarkedAlt style={{}} size="1.7em" />
                                </button>
                                
                            </div>}
                        {this.state.showMap && <MapModal callback={() => {this.setState({showMap: false})}} type={"map"} mapData={{
                            birthPlace: {
                                lat: locationProcessor(this.data.birthPlace)[1], 
                                lng: locationProcessor(this.data.birthPlace)[2],
                                placeName: locationProcessor(this.data.birthPlace)[0]
                            },
                            burialPlace: {
                                lat: locationProcessor(this.data.burialPlace)[1], 
                                lng: locationProcessor(this.data.burialPlace)[2],
                                placeName: locationProcessor(this.data.burialPlace)[0]
                            }
                        }}/>}

                    </div>
                    <br/>
                    <>
                        <p className='sectionTitle'>Family</p>
                        <div className='sectionBody'>

                            <div className='parentsCont' >
                                <p className='smallTitle'>Parents</p>
                                <p className='listItems_kids_siblings'><span>Mother:</span> {this.data.mother && splitAndCapitalise(this.data.mother)}</p>
                                <p className='listItems_kids_siblings'><span>Father:</span> {splitAndCapitalise(this.data.father)}</p>
                            </div>

                            <div className="kidsNSiblingsCont">
                                {this.data.siblings && <div className='siblingsCont'>
                                    <p className='smallTitle'>Siblings</p>
                                    {this.data.siblings ? this.data.siblings.split(',').map((sib, i) => (sib[0] && isNaN(sib)) && <p className='listItems_kids_siblings' key={i}><span>{i+1}. </span>{splitAndCapitalise(sib)}</p>) : 'Either no kids or Info not provided'}
                                </div>}
                                {this.data.children && <div className='kidsCont'>
                                    <p className='smallTitle'>Kids</p>
                                    {this.data.children ? this.data.children.split(',').map((kids, i) => ((kids[0] && isNaN(kids))&& <p className='listItems_kids_siblings' key={i}><span>{i+1}. </span>{splitAndCapitalise(kids)}</p>)) : 'Either no kids or Info not provided'}
                                </div>}
                            </div>
                        </div>
                    </>

                </div>
                <br/>
                <p className='sectionTitle'>Social Media</p>

                <div className="SocialsCont">
                    {this.socialMediaIconSetter(3, this.data.youtubeLink)}
                    {this.socialMediaIconSetter(0, this.data.facebookLink)}
                    {this.socialMediaIconSetter(1, this.data.instagramLink)}
                    {this.socialMediaIconSetter(2, this.data.twitterLink)}
                 </div>
                <br/>
                {this.callType !== "qr" && <div className="qrCodeBtn">
                    <button className='qrBtn' onClick={() => this.qr()}>Generate QR Code</button>
                    <br/>
                    <br/>
                    <div className='newQR'></div>
                    <img id="qrImage" alt='QR Code'/>
                </div>}
                <br/>
                <br/>
                <p className='sectionTitle fileTitle'>Files</p>
                    
                    {this.user && this.user.email === this.data.creator && <UploadFile name={this.data.name} setter={this.setDocs} email={this.data.creator} loid={this.data._id}/>}
                    <div id='filesDiv'>
                    <br/>
                    <p className='Files' />
                    <br/> 
                </div>
                
                 <div id='imagesDiv'>
                    {/* [1] because the first index is used for profile pic */}
                    <p className='sectionTitle imagesTitle'>Images</p>
                    {this.callType === 'qr' && (
                        <div className='imageOptionContainer'>
                        <p>Views:</p>
                        <div>
                        <button className='aloBtn carouselBtn' onClick={() => {this.setState({carousel: true}, this.setImages(true))}}>Slider</button>
                        <button className='aloBtn gridBtn' onClick={() => {this.setState({carousel: false}, this.setImages())}}>Grid</button>
                        </div>
                    </div>
                    )}
                    {this.user && this.user.email === this.data.creator && <div className='uploadImageCont'> 
                        <p className='uploadImageBtn imgBtnStyle' onClick={(e) => this.UploadImage()}>Upload Image</p>
                        <p className='uploadImageBtnReady imgBtnStyle' onClick={(e) => this.UploadAction(e)}>Upload Now</p></div>
                    }
                    
                    <div className="images"/>
                </div>
                {(this.state.urls[0] && this.state.carousel) ? <CarouselHolder images={this.state.urls} /> : <></>}
        </div>}
        </>
    )
}

}

