import React, {useState} from 'react';
import './edit.css'
import sha256 from "js-sha256"
import { Loader } from '../../../utils/loader';
import { ConfirmModal } from '../../Modal/Modal';
import { FaMapMarkedAlt } from "react-icons/fa";
import { MapModal } from "../../Modal/Modal";

export default function EditView(myLO, editId, exitHandle, editSubmitClicked, editedObj=null, editExit, mapData) {
    
    let blacklistEdit = ['_id', 'creator', '__v', 'images', 'comments', 'documents', 'deleted', 'profilePic']
    let person = myLO.filter(p => p._id === editId)
    let {
        mapChoose,
        mapCoordProccessor,
        birthObj,
        deathObj,
        mapChooseType
    } = mapData

    

    function EditComponent() {
        return (
            <div className="editContainer">
                <div className="exitDiv"><p className="centerTxt editTitle ">Editor</p><p className='editExit' onClick={() => exitHandle("close")}>X</p></div>
                <div className='editContent'>
                <br/>
                <p className="noChange"></p>
                <br/>
                <form className='editForm'>
                    <div className="editorContent">
                        {person[0] && Object.keys(restOfPerson).map((post, i) => {
                            if(blacklistEdit.includes(post)) return null
                            if(post === 'dob' || post === 'dod') {
                                let d = new Date(person[0][post]).toISOString().slice(0,10);
                                return (
                                    <div className="inputDiv" key={i}>
                                        <label>{post === 'dod' ? 'Date Of Death' : "Date Of Birth"}:</label>
                                        <input type="date" className={post} defaultValue={d}></input>
                                    </div>
                                )
                            }
                            if(post === "bio") {
                                return (
                                    <div className="inputDiv" style={{marginBottom: '1rem'}} key={i}>
                                        <label>Bio:</label>
                                        <textarea type="text" style={{resize: 'none'}} className={post} 
                                        onChange={(e) => {
                                            e.target.value.replace('\n', '<br/>')
                                        }} 
                                        defaultValue={`${person[0][post]}`} rows="8" cols="25" maxLength='1000' ></textarea>
                                    </div>
                                )
                            } else if (post === "privateInfo") {
                                return null
                            }else {
                                let inputLabel = '';
    
                                switch (post) {
                                    case 'name':
                                        inputLabel = 'Name'
                                        break;
                                    case 'birthPlace':
                                        inputLabel = 'Birth'
                                        break;
                                    case 'burialPlace':
                                        inputLabel = 'Buried'
                                        break;
                                    default:
                                        let [test, ...rest] = post.split('');
                                        inputLabel = String(test.toUpperCase() + rest.join(''));
                                        break;
                                }

                                let mapInputCheck = (label) => {
                                    return (label === "birthPlace" || label === "burialPlace")
                                }

                                let inputDefault = (label) => {
                                    if(mapInputCheck(label)) {
                                        if(label === "birthPlace") {
                                            if(birthObj.place) return birthObj.place
                                        } else {
                                            if(deathObj.place) return deathObj.place
                                        }
                                    } 
                                    return person[0][label]
                                }
    
                                return (
                                  <div className="inputDiv" key={i}>
                                    <label>{inputLabel}:</label>
                                    {mapInputCheck(post) && (
                                      <div className="MapButtonContainer">
                                        <button
                                          className="MapButtons aloBtn"
                                          style={{width: "auto"}}
                                          onClick={(e) => {mapChoose(e, post === "birthPlace" ? "birth" : "death")}}
                                        >
                                          <FaMapMarkedAlt size="1.7em" />
                                        </button>
                                        <input
                                            type="text"
                                            className={post}
                                            value={inputDefault(post)}
                                            disabled
                                        ></input>
                                      </div>
                                    )}
                                    {!mapInputCheck(post) && <input
                                      type="text"
                                      className={post}
                                      value={inputDefault(post)}
                                    ></input>}
                                  </div>
                                );
                            }
                        })}
                        {mapChooseType !== null && <MapModal callback={mapCoordProccessor} type={mapChooseType}/>}


                        <div className="inputDiv">
                            <label>Youtube Link:</label>
                            <input type="text" className={'youtubeLink'} defaultValue={youtubeLink}></input>
                        </div>
                        <div className="inputDiv">
                            <label>Twitter Link:</label>
                            <input type="text" className={'twitterLink'} defaultValue={twitterLink}></input>
                        </div>
                        <div className="inputDiv">
                            <label>Instagram Link:</label>
                            <input type="text" className={'instagramLink'} defaultValue={instagramLink}></input>
                        </div>
                        <div className="inputDiv">
                            <label>Facebook Link:</label>
                            <input type="text" className={'facebookLink'} defaultValue={facebookLink}></input>
                        </div>
                        <div className="inputDiv">
                            <label>Locked with password:</label>
                            <input type="checkbox" className={'privateInfo'} onChange={checkedHandle} defaultChecked={restOfPerson.privateInfo}></input>
                        </div>
                        
                        <div className="inputDiv qrpass" id="qrPass">
                            <label>Reset Password:</label><input type="text" className="qrPassText" onChange={(e) => passwordCheck(e)} placeholder="Leave empty if you dont want to change you PW"></input>
                        </div>
                    </div>
                </form>
                </div>
                <p className="noChange"></p> 
                <div className='btnContainer'><button className='aloBtn' id='editSubmitBtn' onClick={(e) => handleEditSubmit(e)} >Submit</button></div>
            </div>
        )
    }

    let handleEditSubmit = (e) => {
        e.preventDefault();
        let personObj = Object.create(null);
        let pass = null

        Object.keys(person[0]).map((key, i) => {
            try {
                let el = document.getElementsByClassName(key);
                if(!el[0] || el[0] === undefined) return null;
                if(blacklistEdit.includes(key)) {
                    return null;
                } else if(key === 'dob' || key === 'dod') {
                    let check = new Date(person[0][key]).toISOString().slice(0,10)
                    if(check !== el[0].value) {
                        personObj[key] = el[0].value;
                        return null;
                    }
                    return null;
                } else if( key === "privateInfo"){
                    // if checkbox value is same as the current private info value then just return 
                    if(el[0].checked !== person[0][key]) personObj[key] = el[0].checked;
                    return null
                }else if(person[0][key] !== el[0].value) {
                    personObj[key] = el[0].value;
                    return null;                        
                }
                return null;
            } catch (error) {
                console.log(error);
                return null;
            }
        })
        // Iteration END
        try {
            let passwordElement = document.querySelector(".qrPassText")
            if(passwordElement !== null && passwordElement.value !== "" ) {
                var hash = sha256.create();
                hash.update(passwordElement.value);
                let hashed = hash.hex()
                personObj["qrLock"] = hashed;
                pass = passwordElement.value
            } else if( passwordElement.value === "") {
                pass = ""
            } else if( passwordElement == null) {
                console.log("No password needed")

            } 
        } catch (error) {
            // console.log("no pass set, continue", error)
            // throw new TypeError();
        }
        if(Object.keys(personObj).length === 0) {
            // console.log("No change made to loved one")
            let el = document.querySelectorAll('.noChange');
            el.forEach((e) =>{
                e.innerText = 'Make a change to the data to continue!';
            })
            // window.scrollTo(0, 0);
            setTimeout(() => {
                el.forEach((e) =>{
                    e.innerText = '';
                })
                
            }, 2000)
        } else {
            editSubmitClicked(personObj, pass !== null ? pass : null)
        }
    }

    function passwordCheck (e) {
        console.log(e.target.value)
    }

    function checkedHandle(e) {
        let passEl = document.getElementById("qrPass")
        if(e.target.checked) passEl.style.display = "flex";
        else passEl.style.display = "none";
    }

    let {youtubeLink, instagramLink, twitterLink, facebookLink, ...restOfPerson} = person[0];

    
    if(editExit === "openEdit") return EditComponent()
    else if( editExit === "openModal") return  <div className='homeContainer'><ConfirmModal title="Exit" text="Do you want to exit editing?" callback={(bool)=> exitHandle(bool)}/></div>
        
    
}
