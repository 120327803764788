let config = 
    (process.env.NODE_ENV === 'development') ?  {
        'BACKEND_URL':process.env.REACT_APP_TEST_BACKEND_URL,
        "FRONTEND_URL":process.env.REACT_APP_TEST_FRONTEND_URL
    }:{
        'BACKEND_URL':process.env.REACT_APP_BACKEND_URL,
        "FRONTEND_URL":process.env.REACT_APP_FRONTEND_URL
    }




export {config};