import styles from './payment.module.css';
import {postAuthData} from '../../utils/axiosCalls.js';
import { useAuth0 } from '@auth0/auth0-react';
import React, {useState} from 'react';

import {imgLoader} from '../../images/purchaseImages/imgLoader';
import { Loader } from '../../utils/loader.js';
import './index.css'
// import { useState } from 'react';
export default function StripePage() {
    const {isAuthenticated } = useAuth0();
    let images = imgLoader()
    let [loading, setLoading] = useState(false)
    function paymentCard(id, label, price, discount=null) {
        id = Number(id)
        function handle() {
            setLoading(true)
            if(isAuthenticated) {

                postAuthData('/payment/create-checkout-session', JSON.stringify({origin: window.location.origin,items: [{id:Number(id), quantity:1}]}), {headers: {'Content-Type': 'application/json'}}, (res => {
                    if(res.statusText === 'OK' || res.data.url) {
                        let {url} = res.data;
                        // console.log(url)
                        window.location.href = url
                    } else {
                        setLoading(false)
                        console.log('Error in post to endpoint: ', res)
                    }
                }))
            } else {
                setLoading(false)

                let overlay = document.querySelector('#overlayer')
                overlay.style.display = 'flex';
                // overlay.classList.add('loginAnim')
                setTimeout(() => {
                    // overlay.classList.remove('loginAnim')
                    overlay.style.display = 'none';
                }, 3000)
                // send user to login page

            }
        }
        return (
            <div className={styles.formHolder} onClick={() => {handle()}}>
                <input className='paymentInput' style={{'display': 'none'}} value='1' type='checkbox'/>
                <p className={styles.title}>{label}</p>
                <img className={styles.img} src={images[id-1]} alt='qr code' />
                <p></p>
                {discount && <p className={styles.percentOff}>{discount}</p>}
                <p className={styles.price}>{price}</p>
            </div>
        )
    }

    return (
        <div className={styles.mainBox}>
            <form className={styles.formContainer}>
                {paymentCard(1, 'Storage For Single Love One', '$34.99')}
                {paymentCard(2, 'Storage For 3 Loved Ones', '$89.99')}
                {paymentCard(3, 'Storage For 5 Loved Ones', '$139.99')}
                {/* {paymentCard(4, 'Storage For 10 Loved Ones', '$249.99', '$75 off')} */}
                {paymentCard(4, 'Engraved QR Tag', '$49.99')}

                
                {/* {paymentCard(5, 'Storage For 20 Loved Ones', '$1749.99', '$250 off')} */}

            </form>
            <div className={styles.overlayer} id='overlayer'>
                <p>Please Login To Make a Purchase</p>
            </div>
            {loading && <Loader />}
        </div>
    )
}