import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './index.css'

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 300 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };


export default class CarouselHolder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: this.props.images,
        }
    }

    // style={{backgroundImage: `url(${img})`, width:'300px', height: '600px', backgroundSize: 'cover', backgroundRepeat:"no-repeat"}}

    render() {
        return (
            <Carousel responsive={responsive} swipeable={true}
            draggable={false}
            showDots={true}
            ssr={false} // means to render carousel on server-side.
            infinite={true}
            autoPlay={false}
            arrows={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={0}
            containerClass="carousel-container"
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-10-px">
                {this.state.images.map((imageUrl, index) => {
                    return (
                        <div className="slider" key={index}>
                        <img src={imageUrl} onClick={() => window.open(imageUrl, '_blank')} alt="movie" />
                        </div>
                    );
                })}
            </Carousel>
        )
    }
}