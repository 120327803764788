import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
        domain="dev-u12vxq1z.us.auth0.com"
        clientId="TdJp7jPwBFomdAC2NOq1CyxVtqFpWOM4"
        authorizationParams={{
        redirect_uri: window.location.origin + '/callback',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: process.env.REACT_APP_AUTH0_SCOPE
        }}
        cacheLocation="localstorage"
        onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;