let img1 =  require('./angel1.png')
let img2 = require('./angel2.png')
let img3 = require('./angel3.png')
let img4 = require('./funeralDirector.png')
let img5 = require('./angel5.png')
let qrTag = require('./QR Tag.png')


export let imgLoader = () => {
    return [img1, img2, img3, qrTag, img4];
}