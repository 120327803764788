 import React, {useCallback} from "react";
import { useState, useEffect } from 'react';
import PersonCardLrg from '../People/personCard/personCardLrg/personCardLrg';
import { noAuthGet, getAuthData, noAuthPost} from '../../utils/axiosCalls';
import { useParams, useSearchParams } from "react-router-dom";
import { commentComponent } from '../Comment/comment';
import { LeaveComment } from '../Comment/leaveComment';
import {SendEmail} from '../Comment/sendEmailToCreator/sendEmail';
import { Loader } from '../../utils/loader';
import { useAuth0 } from "@auth0/auth0-react";
import sha256 from "js-sha256"
import isLocked from "./isLocked/isLocked";
import {config} from '../../config';



import './qr.css';

let QrRedirect = ({match}) => {
    let [person, setPerson] = useState(false);
    let [comments, setComments] = useState([]);
    let [allComments, setAllComments] = useState([]);
    let [islocked, setIsLocked] = useState('')

    let [BDaycomments, setBDayComments] = useState(false);
    let [leaveComment, setLeaveComment] = useState(false);
    let [emailSend, setEmailSend] = useState(false);
    let [isLoading, setIsLoading] = useState(false);
    let [comType, setComType] = useState(true);
    let id = useParams().id
    let [passwordValidated, setPasswordValidated] = useState("")
    let [is404, setIs404] = useState(false)

    const { user, isAuthenticated } = useAuth0();

   
    let passwordCallback = useCallback((element = null, cb) => {
        
        setIsLoading(true)
        let pass = element;
        let fakeBdayComment = {
            _id: "tester",
            lovedOne: "64ee89f562894d0e856d934f",
            commentor: "sharena.gills@gmail.com",
            comment: "Happy Birthday Freud!! Big fan of your stuff!!",
            pointer: "noelw19@gmail.com/comments_Sigmund Freud/0p2cdkaxtg39.jpeg",
            timestamp: "Thu May 6 2023 15:34:13 GMT+1300 (New Zealand Daylight Time)",
            birthdayComment: true
          }
          let hashVal = null;
            if(pass) {

                var hash = sha256.create();
                hash.update(pass);
                hashVal = hash.hex()
            }

            noAuthPost(`${config.BACKEND_URL}/access/view/qr/${id}`, {pass: hashVal ? hashVal : ""}, (response) => {
                let res = response.data.status;
                if(res === "success") {
                    let lovedone = response.data.data[0]
                    setPerson(lovedone)
                    noAuthGet(`/access/comment/get/${id}`, (commentsArr) => {
                            if(commentsArr.status === "success" && commentsArr.count === 0) {
                                
                            
                            }else if(commentsArr.data[0]) {
                                let commentObjs = commentsArr.data;

                                let birthday = lovedone.dob.slice(0, 10)
                                let BdayCommentsArr = [];
                                commentObjs.forEach(comment => {
                                    let dateOfComment = new Date(comment.timestamp).toLocaleString()
                                    let bday = new Date(birthday).toLocaleString()
                                    let isBday = (bday, date) => {
                                        let [day, month] = bday.split('/')
                                        let [day1, month1] = date.split("/")
                                        return (day === day1 && month === month1)
                                    }

                                    if(isBday(bday, dateOfComment)) {
                                        comment.birthdayComment = true;
                                        BdayCommentsArr.push(comment)
                                    }
                                    // if(comment.pointer) console.log(comment)
                                })
                                if(BdayCommentsArr.length === 0 && (lovedone.name.includes('Sigmund Freud')|| lovedone.name.includes("Kurt Cobain"))) {
                        
                                    BdayCommentsArr.push(fakeBdayComment)
                                }
                                setBDayComments(BdayCommentsArr)
                                
                                setAllComments(commentObjs)
                                setComments(commentObjs)
                            } else {
                                setComments(null)
                            }
                            setIsLocked(false)
                            setIsLoading(false)
                            setPasswordValidated(true)
                    })
                } else {
                    setIsLoading(false)
                    cb()
                }

            })
    }, [id])

    useEffect(() => {
        setIsLoading(true)
          noAuthGet(`/access/check/locked/${id}`, (res) => {
            if (res.data) {

                let {status, hasLock} = res.data
                if(status === "success") {
                    // console.log(res)
                    if(hasLock) {
                        // Lock so call enpoint with password attempt
                        setIsLoading(false)
                        setIsLocked(true)
                        
                        
                    } else {
                        // No lock call data
                        // setIsLocked(false)
                        noAuthPost(`${config.BACKEND_URL}/access/view/qr/${id}`, {pass: ""}, (response) => {
                            let res = response.data.status;
                            if(res === "success") {
                                let lovedone = response.data.data[0]
                                setPerson(lovedone)
                                noAuthGet(`/access/comment/get/${id}`, (commentsArr) => {
                                    if(commentsArr.status === "success" && commentsArr.count === 0) {
                                        setComments(null)
                                    } else if(commentsArr && commentsArr.data && commentsArr.data[0]) {
                                            let commentObjs = commentsArr.data;
            
                                            let birthday = lovedone.dob.slice(0, 10)
                                            let BdayCommentsArr = [];
                                            commentObjs.forEach(comment => {
                                                let dateOfComment = new Date(comment.timestamp).toLocaleString()
                                                let bday = new Date(birthday).toLocaleString()
                                                let isBday = (bday, date) => {
                                                    let [day, month] = bday.split('/')
                                                    let [day1, month1] = date.split("/")
                                                    return (day === day1 && month === month1)
                                                }

                                                if(isBday(bday, dateOfComment)) {
                                                    comment.birthdayComment = true;
                                                    BdayCommentsArr.push(comment)
                                                }
                                            })
                                            
                                            setBDayComments(BdayCommentsArr)
                                            
                                            setAllComments(commentObjs)
                                            setComments(commentObjs)
                                        } else {
                                            setComments(null)
                                        }
                                        setIsLocked(false)
                                        setIsLoading(false)
                                        setPasswordValidated(true)
                                })
                            } 
                        })
                    }
                } else {
                    console.log("failed call", res)
                }
            } else {
                console.log(res.response.status)
                if (res.response.status === 404) {
                    console.log('User with this ID not found')
                    setIsLoading(false)
                    setIs404(true)
                }
            }
          }, '')
    },[id]);
    
    let removeCommentAfterDelete = (index) => {
        // delete has already taken place so by removing the 
        // comment obj from comments re render will provide new view and refresh wont pull deleted data
        let newCommentsAfterRemove = comments.filter((com, i) => i !== index);
        setComments(newCommentsAfterRemove)
        setAllComments(newCommentsAfterRemove)

    }

    let NoCommentsDiv = () => {
        return (
            <div>
                <p>Currently No Comments to be shown</p>
            </div>
        )
    }

    let NoBirthdayCommentsDiv = () => {
        let commentContainer = document.querySelector('.commentContainer');

        let container = document.createElement('div');
        container.classList.add('noBday')
        let messageEl = document.createElement('p');
        messageEl.textContent = 'No birthday Comments at this time.'
        messageEl.style.margin = '1rem';
        messageEl.style.padding = '1rem';
        container.appendChild(messageEl)
        commentContainer.appendChild(container)
    }

    let qrButtons = () => {
        return (
            <div className="qrButtons" >
                    <button className="aloBtn" onClick={() => {}}><a href="#comments">Visitor Comments</a></button>
                    <button className="aloBtn" onClick={() => {setEmailSend(!emailSend)}}>Email Family Member</button>
                    <button className="aloBtn" onClick={function(){setLeaveComment(!leaveComment)}}>Leave A Comment</button>
                </div>
        )
    }

    let Main = () => {

        return (
            <div className='mainParent' style={{display: ((!leaveComment && !emailSend) ? 'flex' : 'none')}}>
                    {person && <PersonCardLrg props={[person]} callType={"qr"} callback={() => {console.log("QR calling back")}}/>  }
                    {(comments && comments !== null) && <div className="commentTypeOptions">
                        <p className="comOption" onClick={commentOptionHandle}>All</p>
                        <p className="comOption notActiveComOption" style={{'borderLeft': '1px solid black'}} onClick={commentOptionHandle}>Birthday</p>
                    </div>}

                    <div className='visitorContainer' id="comments">
                        <h3>Visits</h3>

                        <div className='commentContainer'>
                            {(comments && comments !== null) && comments.map((comment, index) => {
                                
                                return commentComponent(comment, index, user, isAuthenticated, person.creator, id, removeCommentAfterDelete)
                            })}
                            {(comments == null || !comments) && NoCommentsDiv()} 
                            {comments === false && <p>LOADING</p>}
                        </div>
                    </div>
                </div>
        )
    }

    function closeComment() {setLeaveComment(!leaveComment)}
    function commentLoader(bool) {setIsLoading(bool)}

    function commentOptionHandle(e) {
        let currentCommentType = !comType;
        setComType(!comType)
        let parentElement = document.querySelector('.commentTypeOptions')
        let children = parentElement.children
        Object.keys(children).filter(child => {
            if(children[child].classList.contains('notActiveComOption')) {
                children[child].classList.remove('notActiveComOption')
                // console.log(`comOption active: ${children[child].textContent}`)
                let commentContainer = document.querySelector('.commentContainer');
                if(currentCommentType === true) {
                    if(BDaycomments.length === 0) {
                        let toRemove = document.querySelector('.noBday');
                        commentContainer.removeChild(toRemove)
                    }

                    setComments(allComments)
                } else {
                    // commentContainer.
                    if(BDaycomments.length === 0) {
                        NoBirthdayCommentsDiv()
                    }
                    setComments(BDaycomments)
                }
            } else {
                children[child].classList.add('notActiveComOption')
            }
            return null
        })
    }

    function errorPage() {

        return (
            <div className="Err404">
                <p>Error Displaying user</p>
                <p>User with that ID not found.</p>
                <p>ID: {id}</p>
                <button className="qrErrBtn" onClick={() => window.location.reload()}>Refresh Page</button>
            </div>
        )
    }

    return (
        <div className="la">
            {passwordValidated && qrButtons()}
        <div className="qrLinkContainer">
            {passwordValidated && Main()}
            {islocked && isLocked(passwordCallback)}
            {is404 && errorPage()}
            {(!emailSend && leaveComment) && <LeaveComment id={id} closeFunc={closeComment} loader={commentLoader}/>}
            {(emailSend && !leaveComment) && <SendEmail id={id} closeFunc={() => {setEmailSend(!emailSend)}}/>}
            {isLoading && <Loader />}
        </div>
        </div>
    )
}

export default QrRedirect;

