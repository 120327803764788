import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import logoDesktop from '../../images/logos/minimalLogo.png';
import logoMobile from '../../images/logos/logo.png';
import logoMobile1 from '../../images/logos/logo-title.png';
import Hamburger from "./hamburger/hamburger";
import Menu from './hamburger/menu/menu'


import './nav.css';
import { NavLink } from "react-router-dom";
import {useEffect, useState} from 'react';
import LoginBtn from '../authBtns/loginBtn';
import LogoutBtn from '../authBtns/logoutBtn';
import LoaderPage from "../loaderPage/loaderPage";
import {addAccessTokenInterceptor} from '../../utils/axiosCalls';
import { useLocation } from 'react-router-dom';

let Nav = () => {
    const {isAuthenticated, getAccessTokenSilently } = useAuth0();
    const location = useLocation();
    let mobile = window.innerWidth <= 600;
    let singleEventAdder = 0;

    useEffect(() => {
        let links = document.querySelectorAll('.link');
        let logoDiv = document.querySelector('.logoContainer');
        let path = window.location.pathname !== '/callback' ? window.location.pathname : '/';
        localStorage.setItem("pathname", path )
        
        // console.log("Location Change: " + path, location)
        // to reload page when redirected as page stays blank but url is good
       

        addAccessTokenInterceptor(getAccessTokenSilently)
        window.innerWidth >= 700 ? logoDiv.style.backgroundImage = `url('${logoDesktop}')` : logoDiv.style.backgroundImage = `url('${logoMobile1}')` ;


        for(let i = 0; i < links.length; i++) {
            links[i].addEventListener('click', () => {
                window.scrollTo(0, 0);
            })
        }
    }, [getAccessTokenSilently, isAuthenticated, location]);

    let homeNav = (<NavLink className={({ isActive }) => (isActive ? "link active" : "link")} to="/">Home</NavLink>)
    let profileNav = (isAuthenticated && <NavLink className={({ isActive }) => (isActive ? "link active" : "link")} to="/profile">Profile</NavLink>)
    let aboutNav = (<NavLink className={({ isActive }) => (isActive ? "link active" : "link")} to="/about">About</NavLink>)
    let peopleNav = (<NavLink className={({ isActive }) => (isActive ? "link active" : "link")} to="/people/5">People</NavLink>)
    let addLONav = (isAuthenticated && <NavLink className={({ isActive }) => (isActive ? "link active" : "link")} to="/lovedOne/new">Add Person</NavLink>)
    let pricingNav = (<NavLink className={({ isActive }) => (isActive ? "link active" : "link")} to="/purchase">Pricing</NavLink>)
    let geoLocateNav = (<NavLink className={({ isActive }) => (isActive ? "link active" : "link")} to="/geolocator">Geolocator</NavLink>)
    let quoteNav = (<NavLink className={({ isActive }) => (isActive ? "link active" : "link")} to="/quotes">View Quotes</NavLink>)
    let authBtnsNav = (!isAuthenticated ? <LoginBtn/>: <LogoutBtn/> )

    
    function toggleDropDown() {
        document.getElementById("myDropdown").classList.toggle("show");
    }

    let DropDownElement = () => {
        return (
            <div class="dropdown" onMouseEnter={toggleDropDown} onMouseLeave={toggleDropDown}>
                <button class=" dropbtn link">Other</button>
                <div id="myDropdown" class="dropdown-content">
                    {pricingNav}
                    {geoLocateNav}
                    {quoteNav}
                </div>
            </div> 
        )
    }

    let navElementsLrg = (
        <>
            <NavLink className={({ isActive }) => (isActive ? "link active" : "link")} to="/">Home</NavLink>
            {isAuthenticated && <NavLink className={({ isActive }) => (isActive ? "link active" : "link")} to="/profile">Profile</NavLink>}
            <NavLink className={({ isActive }) => (isActive ? "link active" : "link")} to="/about">About</NavLink>
            <NavLink className={({ isActive }) => (isActive ? "link active" : "link")} to="/people/5">People</NavLink>
            {isAuthenticated && <NavLink className={({ isActive }) => (isActive ? "link active" : "link")} to="/lovedOne/new">Add Person</NavLink>}
            <DropDownElement />
            {!isAuthenticated ? <LoginBtn/>: <LogoutBtn/> }
        </>
    )

    let navElementsHamburger = {
            homeNav,
            profileNav,
            aboutNav,
            peopleNav,
            addLONav,
            geoLocateNav,
            pricingNav,
            quoteNav,
            authBtnsNav}

    return (
        <div className='navContainer'>
            <div className='logoContainer' onClick={() => {window.location = '/'}}/>
            <div className='navLinks' style={{fontSize: "1rem", alignItems: "center"}}>
                {window.innerWidth > 600 && navElementsLrg}
                {window.innerWidth < 600 && Hamburger(navElementsHamburger)}
            </div>
        </div>
    );
}

export default Nav;