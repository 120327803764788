import React from "react";
import './sendEmail.css';
import { useState } from 'react';
import { postAuthData } from '../../../utils/axiosCalls';

export let SendEmail = ({id, closeFunc}) => {
    let [sent, setSent] = useState(null)

    let submitHandler = function(e) {
        e.preventDefault();
        console.log('Sending email')
        let message = document.querySelector('#emailMessage').value;
        console.log(message)

        

        postAuthData(`/comments/contact/${id}`, {message}, null, (res) => {
            if(res.status === 'success') {
                console.log(res.status)
                setSent(true)
                setTimeout(() => {closeFunc()}, 2000)
            } else {
                console.log(res.message)
            }
        })

        
    }

    return (
        <div className="sendEmailParent">
            {sent === null && <>
                <div className="emailSendExit"><p onClick={() => closeFunc()}>X</p></div>
            <p className='emailTitle'>Send an email to the family member</p>
            <div className="sendEmailChildContainer">
                <form >
                    <label>Message</label>
                    <textarea id='emailMessage' name="message" cols="30" rows="10"></textarea>
                    <button type="submit" onClick={(e) => submitHandler(e)}>Send</button>
                </form>
            </div>
            </>}
            {(sent !== null && sent)  && <p className='emailTitle'>Successful</p>}
            {(sent !== null && !sent)  && <div className='failedContactEmailContainer'><p className='emailTitle'>Email Send failed. Try again</p><button>Try Again</button></div>}

        </div>
    )
};