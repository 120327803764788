import DOMPurify from 'dompurify';

let cleanInput = (input) => DOMPurify.sanitize(input);

let iterateObjAndClean = (obj) => {
    let newObj = Object.create(null);
    Object.keys(obj).filter(key => {
        let clean = cleanInput(obj[key]);
        newObj[key] = clean
        return null;
    })
    return newObj
    
}

export {cleanInput, iterateObjAndClean};