import {config} from '../../config';

import React from 'react';
import './index.css';
import './cardoverlay.css';
import { getAuthData, editCommentAuth} from '../../utils/axiosCalls';
import img from '../../images/bday/bdayCake.png';
import { FaBirthdayCake } from 'react-icons/fa';

export function commentComponent (obj, i, user, isAuthenticated, owner, id, remover) {
    let imgUrl = '';
    let backendUrl = config.BACKEND_URL;


    // console.log(obj);
    let twelveHourTime = (hr, min) => Number(hr) < 12 ? `${hr}:${min}am` : Number(hr) === 12 ? `${hr}:${min}pm` : `${hr - 12}:${min}pm`;
    let dayOfWeek, month, day, year, time1;
    let hour,minute;


    [dayOfWeek, month, day, year, time1] = obj.timestamp.split(' ');
    [hour, minute] = time1.split(':');
    let date = `${day} ${month} ${year}`
    let time = twelveHourTime(hour, minute)
	

    let addAnim = (type) => {
        let animName = '';
        let message = type;

        let overlayParent = document.querySelector(`.commentCardOverlay${i}`)
        let overlayText = document.querySelector(`.overlayCover${i}`)
        overlayParent.style.display = 'block';
        overlayText.style.display = 'flex';

        if(type === 'Success') {
            animName = 'successAnim';
        }else if (type === 'Failed') {
            animName = 'failAnim';
        } else if(type === 'Cancelled') {
            animName = 'cancelAnim';
        }

        if(animName !== '') {
            overlayText.textContent = message
            overlayParent.classList.add(animName)
            overlayText.classList.add(animName)
            setTimeout(() => {
                overlayParent.classList.remove(animName)
                overlayText.classList.remove(animName)
                overlayParent.style.display = 'none';
                overlayText.style.display = 'none';
            }, 1500)
        }
    }


    let deleteComment = () => {
        let deleteComment = window.confirm(`Do you want to delete comment left by ${obj.commentor.split('@')[0]}`)
        // delete user comments
        if(deleteComment) {
            getAuthData(`/comments/remove/${id}/${obj._id}`, (res) =>{
                if(res.data.status === 'success') {
                    addAnim('Success')
                    setTimeout(() => {
                        remover(i)
                    },2000)                    
                } else {
                    addAnim("Failed")
                    console.log(res.data)
                }
            })
        } else {
            addAnim("Cancelled")
        }
    }

    if(obj.pointer) {
            imgUrl = `https://app.a-lo.life/public/${obj.pointer}`
            
            imgUrl = imgUrl.replace(' ', '%20')

    }

    let commentEditSave = {
        'save': () => {
            let comment = document.querySelector('.textAreaInput').value
            // console.log(comment)
            editCommentAuth(`/comments/set/${obj._id}`, {comment}, (res) => {
                console.log(res.data.status)
                if (res.data.status === 'Success') {
                    addAnim("Success")
                    document.location.reload()

                } else {
                    addAnim("Failed")
                }
                commentComponentCreate()
                bDivCreate()
            })
        },
        'cancel': () => {
            commentComponentCreate()
            bDivCreate()
            addAnim("Cancelled")
        }
    }

    let editComment = () => {
        console.log('Editing: ', obj.comment)
        // selecting this element selects the first comment div instead of the relative div
        // maybe add id to comment class for quick grab of relative div
        let box = document.querySelector(`.commentDiv${obj._id}`);
        box.innerHTML = '';
        
        let domBtnContainer = document.querySelector(`.bDiv${obj._id}`);
        domBtnContainer.innerHTML = '';

        let textInput = document.createElement('textarea');
        let saveBtn = document.createElement('button');
        let cancelBtn = document.createElement('button');
        saveBtn.classList.add('aloBtn')
        cancelBtn.classList.add('aloBtn')
        let btnContainer = document.createElement('div');
        btnContainer.style.display = 'flex';
        btnContainer.style.width= '100%';
        btnContainer.style.gap = "1rem";

        saveBtn.textContent = 'Save';
        cancelBtn.textContent = 'Cancel';

        saveBtn.addEventListener('click', commentEditSave.save)
        cancelBtn.addEventListener('click', commentEditSave.cancel)

        textInput.classList.add('textAreaInput');
        textInput.value = obj.comment
        // rows='13' cols='50' maxLength='1000' 
        textInput.setAttribute('rows', 13)
        textInput.setAttribute('cols', 50)
        textInput.setAttribute('maxlength', 1000)
        textInput.style.width = 'inherit';
        // textInput.style.height = box.style.height - 50;
        textInput.style.resize = 'none';

        btnContainer.appendChild(saveBtn)
        btnContainer.appendChild(cancelBtn)


        box.appendChild(textInput)
        domBtnContainer.appendChild(btnContainer)

        
    }

    let divElementCreate = (clss=null) => {
        let el = document.createElement('div');
        clss && el.classList.add(clss)
        return el
    }

    let pElementCreate = (text, clss=null) => {
        let el = document.createElement('p');
        clss && el.classList.add(clss)
        el.textContent = text
        return el
    }

    let bDivCreate = () => {

        let bottomDiv = document.querySelector(`.bDiv${obj._id}`);
        bottomDiv.innerHTML = '';
        let frag = document.createDocumentFragment();
        let div = divElementCreate()
        let dateEl = pElementCreate(`Date: ${date}`)
        let timeEl = pElementCreate(`Date: ${time}`)
        div.appendChild(dateEl)
        div.appendChild(timeEl)
        frag.appendChild(div)

        bottomDiv.appendChild(frag)
    }

    let commentComponentCreate = () => {
        let box = document.querySelector(`.commentDiv${obj._id}`);
        box.innerHTML = '';
        let div = divElementCreate('commentBox')
        let com = pElementCreate(obj.comment)
        div.appendChild(com)
        box.appendChild(div)
    }

    
    // console.log( base64);
        return (
            <div className={`commentParent comment${i} ${obj.birthdayComment ? "birthdayComment" : ''}`} key={i}>
                {/* {obj.birthdayComment && <img className='bdaycakeImg' src={img} alt='Birthday Cake'/>} */}
                {(user && (obj.commentor === user.email || user.email === owner)) && <div className='exitBtn'><p onClick={() => editComment()}>E</p><p onClick={() => deleteComment()}>X</p></div>}
                <div className={`commentor ${obj.birthdayComment && "birthdayTitle"}`}><p>{obj.birthdayComment ? <> <FaBirthdayCake /> Birthday Comment <FaBirthdayCake /> </>: obj.commentor.split('@')[0]}</p></div>
                {/* {obj.birthdayComment && <div className='balloon'/>} */}
                
                <div className='commentAndPicContainer'>
                    <div className={`commentDiv commentDiv${obj._id}`}>
                            <p className={`commentBox `}>{obj.comment}</p>
                        {/* <div className="commentBox">
                        </div> */}
                    </div>
                    
                    {(obj.pointer && !obj.pointer.includes('false')) && <div className="VisitImageDiv">
                         <img src={imgUrl} alt="Visitor Comment" onClick={() => {window.open((imgUrl), '_blank')}}/>
                    </div>}
                </div>

                <div className={`bottomDiv bDiv${obj._id}`}>
                    {/* <p className='commentor'>{obj.commentor.split('@')[0]}</p> */}
                    <div className='commentDateTime'>
                        <p>{date}</p>
                        {/* {obj.birthdayComment && <div className='bdayCakeContainer'><img className='bdaycakeImg' src={img} alt='Birthday Cake'/></div>} */}
                        {obj.birthdayComment ? <p>{obj.commentor.split('@')[0]}</p> : null}
                        <p>{time}</p>
                    </div>
                </div>

                <div className={`commentCardOverlay commentCardOverlay${i}`}>
                    <div className={`cardOverlay cardOverlay${i}`}></div>
                    <div className={`overlayCover overlayCover${i}`}>Failed</div>
                </div>
            </div>
        )
}

