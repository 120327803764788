import React, { useRef, useState, useEffect } from "react";
import "./hamburger.css";
import Menu from "./menu/menu";

export default function Hamburger(menuItemsArr) {
  const didMount = useRef(false);
  let [hmenu, sethmenu] = useState(false)
  let [initialClick, setInitialClick] = useState(false)

  useEffect(() => {
    let icon = document.querySelector('#ham')
    // toggleRef.current.classList.toggle(Styles.active);
    let menuItems = document.querySelectorAll('.menuItemContainer')
    let menu = document.querySelector('.menuSlider')
    let backlayer = document.querySelector('.hamburgerLink');
    // initialClick allows the callback to only be run when a user interacts instead of on load
    if (didMount.current && initialClick) {
      
      if(hmenu) {
        backlayer.style.visibility = "visible"
        menu.classList.remove('slideOut')
        menu.classList.add('slideIn')
      } else {
        console.log("rinning: " , backlayer.onClick)
        menu.classList.remove('slideIn')
        menu.classList.add('slideOut')
        setTimeout(() => {
            backlayer.style.visibility = "hidden"

        }, 200)
      }
    } else {
        
        menuItems.forEach(menus => menus.childNodes.forEach(menuEl => {
                menuEl.addEventListener('click', () => {
                  
                  menu.classList.remove('slideIn')
                  menu.classList.add('slideOut')
                  setTimeout(() => {
                      sethmenu(false);
                      icon.classList.remove('active')
                      backlayer.style.visibility = "hidden"
                    }, 200)
                })
        }))
        didMount.current = true;
        return;
    }
  }, [hmenu, initialClick])

  const handleHamburgerMenu = (event) => {
    event.preventDefault();
    sethmenu(!hmenu);
    setInitialClick(true);
  };

  return (
    <>
      <div
        className={`hamburger ${hmenu && 'active'}`}
        id="ham"
        onClick={handleHamburgerMenu}
      ></div>

      <div>
        <Menu children={menuItemsArr}/>
      </div>
    </>
  );
}
