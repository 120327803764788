import React, { Children, useState } from "react";
import './index.css'
import MapComponent from "../gmap/mapComponent";
import {
    useMap,
    useMapsLibrary,
  } from "@vis.gl/react-google-maps";

export class ConfirmModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            text: this.props.text,
            callback: this.props.callback
        }
    }

    confirm() {
            this.state.callback(true)
    }

    cancel() {
        this.state.callback(false)
    }

    render() {
        return (
            <div className="modalContainer">
                <p className="modalTitle">{this.state.title}</p>
                <div className="modalText">{this.state.text}</div>
                <div className="modalBtnConatiner">
                    <button className="aloBtn" onClick={() => this.confirm()}>Exit</button>
                    <button className="aloBtn" onClick={() => this.cancel()}>Cancel</button>
                </div>
            </div>
        )
    }
}

export class InfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            text: this.props.text,
            callback: this.props.callback
        }
    }

    confirm() {
            this.state.callback(true)
    }

    render() {
        return (
            <div className="modalContainer">
                <p className="modalTitle">{this.state.title}</p>
                {this.state.text}
                <div className="modalBtnConatiner">
                    <button className="aloBtn" onClick={() => this.confirm()}>OK</button>
                </div>
            </div>
        )
    }
}

export class MapModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.type,
            coords: {lat: "", lng: ""},
            callback: props.callback,
            placeName: "",
            mapData: !props.mapData ? {} : props.mapData,
            currentCenter: "born"
        }
    }

    componentDidUpdate(prevprops, prevstate) {
        if(prevstate.coords !== this.state.coords) {
            let {lat, lng} = this.state.coords

            fetch(
                'https://maps.googleapis.com/maps/api/geocode/json?address=' +
                  lat +
                  ',' +
                  lng +
                  '&key=' +
                  process.env.REACT_APP_GMAP_TOKEN,
              )
                .then(response => response.json())
                .then(responseJson => {
                  if (responseJson.status === 'OK') {
                    console.log(responseJson.results[0].formatted_address)
                    this.setState({placeName: responseJson.results[0].formatted_address})
                  } else {
                    console.log(responseJson)
                  }
                })
                .catch(error => {
                    console.log(error)

                });
        }
    }
      

    confirm(e) {
        e.preventDefault()
        this.state.callback(true, {...this.state.coords, place: this.state.placeName, type: this.state.type})
    }

    cancel(e) {
        e.preventDefault()
        this.state.callback(false)
        
    }

    render() {
        return (
            <div className="modalContainer mapModal">
                <p className="modalTitle">{this.state.mapData ? "Locations" :"Choose the location"}</p>
                <div style={{width: "100%", height: "100%"}}>
                    <MapComponent
                        lat={0}
                        lng={0}
                        mapData={{...this.state.mapData, currentCenter:this.state.currentCenter}}
                        lib={this.state.lib}
                        callback={(coords) => {
                            this.setState({coords: {...coords}})
                        }} />
                {this.state.placeName && <p style={{textAlign:"center", marginTop: "1rem", fontSize:"1.2rem"}}><span style={{fontWeight: "bold"}}>Location:</span> {this.state.placeName}</p>}
                </div>
                {/* <div style={{display: "flex", width: "100%", justifyContent:"space-around"}}>
                <p>Latitude: {this.state.coords.lat || "Not Set"}</p>
                <p>Longitute: {this.state.coords.lng || "Not Set"}</p>

                
                </div> */}
                {this.state.mapData.burialPlace && <div style={{display:"flex", gap:"1rem"}}>
                    <button className={`aloBtn ${this.state.currentCenter === "born" ? "clickBackground" : ""}`} disabled={this.state.currentCenter === "born"} style={{padding: "5px"}} onClick={() => {this.setState({currentCenter: "born"})}}>View Born</button>
                    <button className={`aloBtn ${this.state.currentCenter === "buried" ? "clickBackground" : ""}`} disabled={this.state.currentCenter === "buried"} style={{padding: "5px"}} onClick={() => {this.setState({currentCenter: "buried"})}}>View Buried</button>
                </div>}
                <div className="modalBtnConatiner">
                    <button className="aloBtn" onClick={(e) => this.confirm(e)}>{this.state.type === "map" ? "Close" : "Ok"}</button>
                    {this.state.type !== "map" && <button className="aloBtn" onClick={(e) => this.confirm(e)}>cancel</button>}
                </div>
            </div>
        )
    }
}
