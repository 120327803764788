import React from "react";
import './leaveComment.css';
import { startup, vidStop ,addVideoStream, resizeMe } from './takePicFunctions';
import { useState, useEffect } from 'react';
import {postAuthData } from '../../utils/axiosCalls';
import { useAuth0 } from '@auth0/auth0-react';
import { CallLogin } from "../authBtns/loginCaller";
import { Loader } from "../../utils/loader";



export let LeaveComment = ({id, loader, closeFunc}) => {
    let { isAuthenticated } = useAuth0();
    let isLoading = false
    let [takePic, setTakePic] = useState(false);
    let [ran, setRan] = useState(false);
    let [isMobile, setIsMobile] = useState(false)
    let isSmallScreen = window.innerWidth <= 700;
    let[ mobilePic, setMobilePic] = useState(false)
    // let [comment, setComment] = useState(false);
    // let [img, setImg] = useState(false);
    let count = 0;
  // Set up our event listener to run the startup process
  // once loading is complete.
  if(!isAuthenticated) {
        
            CallLogin()
        }
  useEffect(() => {
      function checkUserAgent() {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
           setIsMobile(true) ;
        } else {
           setIsMobile(false) ;
        }
        
        }

        console.log('auth: ', isAuthenticated)


        checkUserAgent()

    //   statement to make sure the pic function only runs once not twice resulting in errors
      if(!ran && count ===0) {
      let doc = document.querySelector('.leaveComment');

        setRan(!ran)
        // console.log('ran')
        count++;
        doc.addEventListener('load', startup(), false)
      }
  }, [ran, count, isAuthenticated])

  let postComment = ({id, commentInput, img}) => {
    isLoading = true
    let timestamp = Date(Date.now()).substring(0, 24)
    console.log(timestamp)
    postAuthData('/comments/set', {id, commentInput, img, timestamp }, null, (res) =>{
        console.log('uploader: ', res.data, res.status);
        window.location.reload();

    });
  }

  function compressImage(blobURL, {max_width, max_height}) {
    let el = document.querySelector('.mobileImageContainer')
    var image = new Image();
      image.src = blobURL;
      //preview.appendChild(image); // preview commented out, I am using the canvas instead
      image.onload = function() {
        // have to wait till it's loaded
        var resized = resizeMe(image, {max_width, max_height}); // send it to canvas
        var newinput = document.createElement("img");
        // newinput.type = 'hidden';
        // newinput.name = 'images[]';
        newinput.alt = 'preview';
        newinput.src = resized; // put result from canvas into new hidden input
        el.appendChild(newinput);
        setMobilePic(resized)
      }
  }

  let mobileOnChangeHandle = async(e) => {
    let mobileImg = document.querySelector('#mobileImageElement');
    var max_width = mobileImg.getAttribute('data-maxwidth') || 800;
    var max_height = mobileImg.getAttribute('data-maxheight') || 800 ;
    let {files} = mobileImg
    console.log('current: ', {max_width, max_height})
    let dataUrl = await new Promise(r => {let a=new FileReader(); a.onload=r; a.readAsDataURL(files[0])}).then(e => e.target.result);
    compressImage(dataUrl, {max_width, max_height})
  }

  let submitHandler = async (e) => {
    if(isAuthenticated) {

        e.preventDefault();
        loader(true);
        let img = document.querySelector('#canvas').toDataURL("image/png");
        let commentInput = document.querySelector('.commentText').value;
        let picTaken = document.querySelector('#startbutton').textContent === 'Cancel'  && takePic? true : false;

        // console.log(img)
        // setComment(commentInput);
        // setImg(img);
        if(commentInput[0]) {
            if(isMobile) {
                // let mobileImg = document.querySelector('#mobileImageElement');
                // let {files} = mobileImg
                // console.log('current: ', files[0])
                // let dataUrl = await new Promise(r => {let a=new FileReader(); a.onload=r; a.readAsDataURL(files[0])}).then(e => e.target.result);
                //     compressImage(dataUrl)
                //     console.log("mobile upload")
                    
                    postComment({id, commentInput, img:mobilePic ? mobilePic : null})
                } else {
                    
                    postComment({id, commentInput, img:picTaken ? img : null})

                }
        } else {
            window.alert('Cannot leave an empty message on posts. Try again')
            loader(false);
        }
    } else {
        e.preventDefault()
        window.alert('Please login to post a visitor comment.')
    }
    
  }
    // startup();
    return (
        <div className="leaveComment">
            <div className="commentExit"><p onClick={function () {
                // if no vid stream no need to stop (causes error)
                takePic && vidStop();
                closeFunc();
                }}>X</p></div>
            <p className="leaveCommentTitle">Leave a comment for others to see!</p>
            <div className="leaveCommentChildContainer">
                <form>
                    <label>Leave A Comment: </label>
                    <textarea className='commentText' name='comment' type="text" rows='13' cols={(isMobile || isSmallScreen) ? '35' : '50'} onChange={(e) => {
                        e.target.value.replace('\n', '<br/>')
                    }} maxLength='1000' resize='vertical'></textarea>
                    {/* <label></label> */}
                <button className='aloBtn picBtn' onClick={function(e) {
                    e.preventDefault();
                    takePic ? vidStop() : addVideoStream();
                    
                    setTakePic(!takePic)

                }
                    }>{!takePic ? 'Take A Visit Pic' : 'I Dont Want To Take A Visit Pic'}</button>
                <div className="contentarea" style={{display: (!isMobile && takePic ? 'flex' : 'none')}}>
                    <div className="camera">
                        <video id="video">Video stream not available.</video>
                        <canvas id="canvas"></canvas>
                        <button className='aloBtn' id="startbutton">Take Photo</button>
                    </div>
                </div>

                {(isMobile && takePic) && <>
                    <div className="mobileImageContainer" alt='preview'></div>
                    <input id='mobileImageElement' type="file" accept="image/*" capture onChange={(e) => {
                        console.log('image added', e.target.files)
                        mobileOnChangeHandle(e)
                    }}></input>
                </>}
                <button className='aloBtn' onClick={function(e) {submitHandler(e)}}>Submit</button>

                </form>
                    <div className="errorCheck"></div>
            </div>
            {isLoading && <Loader/>}
        </div>
    )
}