import React from "react";
import './index.css'
import '../../nav.css'

let menuDivider = (children) => {
    return (
        <div className="menuItemParent" >
            <div className='menuItemContainer'>
                <div className="hamburgMenu">
                    {children}
                </div>
            </div>
        </div>
    )
}

let Menu = (props) => {

    let menuItems = [
        'homeNav',
        'profileNav',
        'aboutNav',
        'peopleNav',
        'addLONav'
    ];
    
    let getItems = (type) => {
        return (
            Object.keys(props.children).map((key, i) => {
                if(type === 'menu') {
                    if(menuItems.includes(key)) {
                        return props.children[key]
                    }
                } else if (type === 'other') {
                    if(!menuItems.includes(key) && key !== "authBtnsNav") {
                        return props.children[key]
                    }
                }
            })
        )
    }

    

    return (
        <div className='hamburgerLink'>
            <div className="menuSlider">
                <div>
                    <p className='menuHeader'>Menu</p>
                    {menuDivider(getItems('menu'))}
                </div>
                <div>
                    <p className='menuHeader' style={{"borderTop": "2px solid orange"}}>Other</p>
                    {menuDivider(getItems('other'))}
                </div>
                {menuDivider(props.children.authBtnsNav)}
            </div>
        </div>
    )
}

export default Menu;