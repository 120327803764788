import React from 'react';
import './personCard.css';
import blankAvatar from '../../../images/blankAvatar.png'
import { noAuthGet } from '../../../utils/axiosCalls';
import { config } from '../../../config';


class PersonCard extends React.Component {
    constructor(props) {
    super(props);
        // using props.props to access data
        this.state = {
            viewButtons: false,
            count: null
        };
        this.data = props.props;
        this.callback = props.clickCallback || null;
        this.profileCallback = props.profileCallback || null;
        this.profView = props.profView || null;
        this.deleteCall = props.deleteCall || null;
        // sec is bool to show admin is viewing other users LO
        this.sec = props.sec || false;
        this.clicked = false;
        this.buttons = false;

        this.handleClick = this.handleClick.bind(this);
        this.clickStylingHandler = this.clickStylingHandler.bind(this);
        this.mainView = this.mainView.bind(this);
        this.convertDate = this.convertDate.bind(this);
        this.buttonsView = this.buttonsView.bind(this);
    }

    componentDidMount() {
        this.insertImageTag(blankAvatar)
        this.profilePic()
        
    }

    clickStylingHandler() {
        let pEl = document.querySelectorAll('.pCardParent');

        for(let i = 0; i < pEl.length; i++) {
            let name = pEl[i].childNodes[2].textContent;
            let id = pEl[i].childNodes[1].textContent;

            if(name === this.data.name && id === this.data._id) {
                pEl[i].style.width = '100vw';
                pEl[i].style.height = '100vh';
                pEl[i].style.zIndex = '3';

            }
        }
    }

    handleClick(e) {
        e.preventDefault();
        this.clicked = !this.clicked;
        
        if(this.callback) this.callback(this.clicked)
        else if (this.profileCallback) {
            // add edit or view buttons here for profile page
            this.setState({
                viewButtons: !this.state.viewButtons
            })

            setTimeout(() => {
                this.setState({
                    viewButtons: !this.state.viewButtons
                })
            }, 3000)
        }
    }

    insertImageTag(img) {
        try {
            let imgParent = document.querySelector(`.prof${this.data._id}`);
            let imgcontainer = document.querySelector(`.pic${this.data._id}`);

            imgParent.style.display = "block";
            imgcontainer.style.backgroundImage = `url(${img})`;
            imgcontainer.style.backgroundSize = "cover";


            imgcontainer.style.border = "1px solid black";
        } catch (error) {
            console.log(error.message)
        }
    }

    async profilePic() {
        let {images, profilePic} = this.data;
        let profLink = profilePic ? profilePic : images.split(',')[0];

        if(profLink[0]) {
            let profilePicUrl = `https://app.a-lo.life/public/${profLink}`
            this.insertImageTag(profilePicUrl)
            // else {this.insertImageTag(`${config.BACKEND_URL}/public/${profLink}`)}
        }
    }

    convertDate(data) {
        let months = {
            "01": "January",
            "02": "Febuary",
            "03": "March",
            "04": "April",
            "05": "May",
            "06": "June",
            "07": "July",
            "08": "August",
            "09": "September",
            "10": "October",
            "11": "November",
            "12": "December"
        }
        let windowWidthGT = window.innerWidth > 800
        var date = new Date(data);
        var convertedDate = date.toISOString().slice(0,10);
        // now rever from year-month-day to day-month-year
        let converted = convertedDate.split('-').reverse()
        converted[1] = windowWidthGT ? months[converted[1]] : months[converted[1]].slice(0,3)
        // console.log(val)
        return converted.join('/')
    }

    buttonsView() {
        // console.log(this.data._id)
        if(this.sec === false) {
            return (
                <div className='optionBtns'>
                    <button className='aloBtn' onClick={() => this.profView(this.data._id)}>View</button>
                    <button className='aloBtn' onClick={() => this.profileCallback(this.data._id)}>Edit</button>
                    <button className='aloBtn' onClick={() => this.deleteCall(this.data._id)}>Delete</button>
                </div>
            )
        } else {
            return (
                <div className='optionBtns'>
                    <button className='aloBtn' onClick={() => this.profView(this.data._id)}>View</button>
                    <button className='aloBtn' onClick={() => this.deleteCall(this.data._id)}>Delete</button>
                </div>
            )
        }
    }

    mainView() {
        let capStr = (str = null) => str !== null || str !== undefined ? str[0].toUpperCase() + str.split('').map((char, i) => i !== 0 ? char : null).join('') : str;
        let splitAndCapitalise = (str) => str.includes(' ') ? str.split(' ').map(name => name ? capStr(name): null).join(' ') : capStr(str);
        let {name, dob, dod} = this.data;

        return (
            <div className={`smallCardHolder`}>
                    <div className={`ProfilePicCardContainer1 prof${this.data._id}`}>
                        <div className={`profilePicCard1 pic${this.data._id}`} />
                    </div>
                <div className='pCardParent' onClick={(e) => this.handleClick(e)}>
                    {this.data.deleted && <p>Deleted by User</p>}
                    <p className="personName">{splitAndCapitalise(name.toLowerCase())}</p>
                    <div className="flexRow">
                        <p><strong>Born:</strong> {this.convertDate(dob)}</p>
                        <p><strong>Died:</strong> {this.convertDate(dod)}</p>
                    </div>
                    
                    {this.state.viewButtons && this.buttonsView()}
                </div>
            </div>
        )
    }

    render() {
        
        return (
            this.mainView()

        )
    }
    
};

export default PersonCard;