import React, {useEffect, useState} from 'react';
import './peoplePage.css'
import PersonCard from './personCard/personCard.js';
import { noAuthGet } from '../../utils/axiosCalls';
import PersonCardLrg from './personCard/personCardLrg/personCardLrg';
import {Loader} from '../../utils/loader';
import { useAuth0 } from '@auth0/auth0-react';
import Paginate from '../paginateComponent/paginate';
import { useParams } from 'react-router';

let People = () => {
    let [people, setPeople] = useState(null);
    let [page, setPage] = useState(1);
    let [clickData, setClickData] = useState(null);
    let [loading, setLoading] = useState(false);
    // let [inputState, setInputState] = useState('');
    let [peopleCache, setPeopleCache] = useState(null);
    let [search, setSearch] = useState('');
    let [prevSearch, setPrevSearch] = useState('')
    
    let perpage = useParams().pp

    let {user} = useAuth0()
    
    useEffect(() => {
        setLoading(true)
        const controller = new AbortController();
        if(search !== null && search !== '') {
            
           try {
                noAuthGet(`/access/search/${search}`, (res) => {
                    if(res.code && res.code === 'ERR_CANCELED') {
                        // const controller1 = new AbortController();
                        console.log('Search Abort')
                        // noAuthGet(`/access/lovedones/page/${page}`, (res) => {
                        //     res.data && console.log(res)
                        //     if(res !== undefined && res.data !== undefined) {
                        //         console.log(res.data.length, res.data)
                        //         setPeople(res.data)
                        //         setPeopleCache(res.data)
                        //         setLoading(false)
                        //     }
                        // }, {signal: controller1.signal})
                    } else {
                        if(res.data === undefined || (!res.data && res.data.length === 0)) {
                            setPeople(null)
                            setLoading(false)
                            
                        } else {
                            console.log(res.data.length, res.data)
                            setPeople(res.data)
                            setPeopleCache(res.data)
                            setLoading(false)
                        }
                    }
                }, {signal: controller.signal})
           } catch (error) {
                console.log(error)
           }


        
        } else {

            noAuthGet(`/access/lovedones/page/page=${page}/perPage=${perpage}`, (res) => {
                setPeople(res.data)
                setPeopleCache(res.data)
                setLoading(false)
            })
        }
        window.scrollTo(0,0);
        return () => {
            controller.abort();
          };
    }, [page, search, perpage])

    let pageSetCallback = (page) => {
        setPeople(null)
        setPeopleCache(null)
        setPage(page);
    }

    let clearClickDatacallback = () => setClickData(null);

    let handleInputChange = (e) => {
        let searched = e.target.value.toLowerCase()
        let cleanedSearchString = searched.replaceAll(/[^a-zA-Z-']+/g, "")
        if (people === null && prevSearch.length < cleanedSearchString.length) {
            e.target.value = prevSearch
        } else  {
            setPeople(null); 
            
            let el = document.getElementsByClassName('peopleContainer');
            setPrevSearch(cleanedSearchString)

            if(cleanedSearchString === '') {
                e.target.value = cleanedSearchString
                setSearch('')
                return 
            }

            e.target.value = cleanedSearchString
            el.textContent = '';
            setPeople('');
            setSearch(cleanedSearchString)
        }
        
    }

    return (
        <div className={!clickData ? "peoplePageCaseContainer" : 'LrgCardContainer'}>
            {(search === '' && people && !clickData && window.innerWidth < 700) && <Paginate pageSet={pageSetCallback} currPage={page} maxPerPage={perpage}/>}
            
            {/* {(people && clickData) && <PersonCardLrg props={[people[clickData[1]]]} user={user} callback={clearClickDatacallback}/> } */}
            {!clickData  && <div style={{width: '100%', height: 'auto', padding: '1rem 1rem 0px 0px', display: 'flex', flexDirection: "column", justifyContent: 'flex-start', alignItems: "flex-end", position: 'fixed', zIndex:"10"}}>
                <input className='searchInput' type="text" onChange={(e) => {handleInputChange(e)}} style={{width: '30%', height: '10px', padding: '1rem', marginRight: '1rem', borderRadius: '25px', border: "1px solid black",appearance: "none", borderColor: 'black'}} placeholder='Search'/>
            {(people && !clickData) && <div className="peoplePageTitle"><p className='titlePP'>Loved Ones Added By Others</p></div>}
            </div>}
            {(!people || !clickData) && <div className={people && !clickData ? "peopleParent" : "noLO"}>
                

                    {(!people && !loading) && <div className='noLOContainerPeoplePage'><div className='noLOContent'><p>No Loved Ones with that name</p><p>Try again</p></div></div>}
                    {people && <div className="peopleContainer">
                    {/* if people state has values & nothing has been clicked*/}
                    {(people && !clickData) && people.map((person, i) => {
                        return (
                            <PersonCard key={i} props={person} clickCallback={(click) => {
                            //    onclick sends all users to qr personcardlrg
                                window.location.href = `/qr/${person._id}`
                            }}/>
                            )
                        })}
                    {/* if there are people and there has been a click */}
                    {(!people && !clickData && loading) && <Loader/>}
                </div>}
            </div>}
            {(search === '' && people && !clickData && window.innerWidth > 700) && <Paginate pageSet={pageSetCallback} currPage={page} maxPerPage={perpage}/>}

        </div>
    )
}

export default People;