import React, {useState} from 'react';
import { noAuthPostWHeader } from '../../../utils/axiosCalls';
import {Loader} from '../../../utils/loader';

export let GeolocatorInputContainer = (callback, setShowMap) => {
    let [isLoading, setIsLoading] = useState(false);

    let isImage = (f) => {
        let {name, type} = f
        let imageTypes = ['jpeg', 'jpg', 'png']
        let is = false

        if(name.split('.').length === 2) {
            imageTypes.forEach(t => {
                if (type.includes(t)) {
                    is= true
                }
            })
        }
        return is
    }
  
    let geoImageHandler = (e) => {
      setIsLoading(true);
      setShowMap(false);

      let file = e.target.files[0];
      let isImageFile = isImage(file)
      if(!isImageFile) {
        setIsLoading(false)
        e.target.value = null;
        callback(true, "Not Image File")
        return
      }
      const fd = new FormData();
      // Iterate over all selected files
      fd.append(`geoImage`, file);
      noAuthPostWHeader(
        `/geolocator/image`,fd,
        {headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        (res) => {
          setIsLoading(false)
        //   if no err return err false and data array
          if (res.data.lat || !isNaN(res.data.long)) {
            let { lat, long } = res.data;
            callback(false, [lat, long]);
          } else {
            // else return true null
            e.target.value = null;
            callback(true, null)
          }
        }
      );
    };
  
    return (
      <div className="inputImgContainer">
        <input
          className="geoImg"
          id="file"
          onChange={geoImageHandler}
          type="file"
          name="fileToUpload"
          multiple
        ></input>
        {isLoading && <Loader />}
      </div>
    );
  };