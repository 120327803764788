import React from "react";
import Nav from "./components/Nav/nav";
import Home from "./components/Home/home";
import About from './components/About/about';
import LovedOneAdder from './components/LovedOne/lovedOne';
import People from "./components/People/peoplePage.js";
import Profile from "./components/Profile/profile";
import QrRedirect from './components/Qr/qr';
import PersonCardLrg from "./components/People/personCard/personCardLrg/personCardLrg";
import Support from './components/Support/support';
import StripePage from '../src/components/payment/paymentComponent';
import Callback from "./components/callback/callback";
import { useAuth0 } from "@auth0/auth0-react";
import Err404 from './components/404/404.js'
import GoogleMap from "./components/gmap/gmap.jsx";
import LoaderPage from "./components/loaderPage/loaderPage.js";

import { Routes, Route, Navigate} from "react-router-dom";
import "./App.css";
import './utils/tooltip/tooltip.css'


function RequireAuth({ children }) {
  const accessToken = localStorage.getItem("@@auth0spajs@@::TdJp7jPwBFomdAC2NOq1CyxVtqFpWOM4::@@user@@");
  const refreshToken = localStorage.getItem("@@auth0spajs@@::TdJp7jPwBFomdAC2NOq1CyxVtqFpWOM4::Rememberance_api::openid profile email offline_access"); 

  const { isAuthenticated } = useAuth0();
  if(isAuthenticated) {
    return children
  } else if (accessToken && refreshToken) {
    return children
  } else {
    return <Navigate to={'/'} />
  }
}

function App() {
  return (
      <div id="App" >
          <Nav />
        <div className="container">
          <Routes>
            <Route path="*" element={<Err404 />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/callback" element={
                <Callback />
          }/>
            <Route exact path="about" element={<About />} />
            <Route path='profile' element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            } />
            <Route path='profile/view/:id' element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            } />
            <Route exact path="lovedOne/new" element={
              <RequireAuth>
                <LovedOneAdder />
              </RequireAuth>
            } />
            <Route exact path='people/:pp' element={<People />} />
            <Route exact path='lovedOne/view/:id' render={(props) => <PersonCardLrg {...props}/>} />
            <Route exact path='qr/:id' element={<QrRedirect />} />
            <Route exact path="support" element={
              <RequireAuth>
                <Support />
              </RequireAuth>
            } />
            <Route exact path="purchase" element={
                <StripePage />
            } />
            <Route exact path="geolocator" element={
                <GoogleMap />
            } />
            <Route exact path="quotes" element={
                <LoaderPage />
            } />
            

          </Routes>
        </div>

      </div>
  );
} 

export default App;
