import axios from 'axios';
import {config} from '../config';

let backendUrl = config.BACKEND_URL;

// backendUrl = 'http://localhost:5000'


const httpClient = axios.create();

// adds access tokens in all api requests
// this interceptor is only added when the auth0 instance is ready and exports the getAccessTokenSilently method
const addAccessTokenInterceptor = (getAccessTokenSilently) => {
  httpClient.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

;

function noAuthCitiesGet(endpoint, options, headers, callback) {
    try {
        const source = axios.CancelToken.source();
        const cancelToken = source.token;
        axios.get(endpoint, cancelToken, options, {headers: headers}).then((res) => {
            callback(res)
            
        })
        } catch (error) {
            callback(false)
        }
}

function noAuthGet(endpoint,  callback, cancelToken='') {
    axios.get(backendUrl + endpoint, cancelToken).then((res) => {
        // console.log(res)
        callback(res)
    }).catch((e) => {
        // console.log("error in no auth get", e)
        callback(e)
    })
}

function noAuthGetTest(endpoint,  callback) {
    axios.get(endpoint).then((res) => {
        callback(res)
    })
}

function noAuthPost(endpoint, data,  callback) {
    axios.post(endpoint, data).then((res) => {
        callback(res)
    })
}

function noAuthPostWHeader(endpoint, data, header,  callback) {
    axios.post(backendUrl + endpoint, data, {headers: header}).then((res) => {
        callback(res)
    }).catch(err => {
        callback(err)
    })
}




// Get that calls endpoint with token in the header.
// endpoint is the URL after the root domain i.e /api/posts
let getAuthData = async (endpoint, callback) => {
        
    try {
        const response = await httpClient.get(backendUrl + endpoint)
        callback(response)
    } catch (err) {
        console.log("Err in axios call: " + err.message)
        callback(false)
    }
}

let postAuthData = async (endpoint, data, headers, callback) => {
    try {
        const response = await httpClient.post(backendUrl + endpoint, data, headers)
        callback(response)
    } catch (err) {
        console.log(err)
        if(err.response === undefined) callback(err)
        else if(err.response.status === 409) callback(err.response)
        else {
            console.log("Err in post axios " + endpoint +" call: " + err.message)
        }   
    }
}

// comments update

let editCommentAuth = async (endpoint, data, callback) => {
    try {
        const response = await httpClient.put(backendUrl + endpoint, data)
        callback(response)
    } catch (err) {
        console.log("Err in put axios " + endpoint +" call: " + err.message)
        // if(err.response.status === 409) callback(err.response)
    }
}


let postDeleleAuthData = async (endpoint, data, headers, callback) => {
    try {
        const response = await httpClient.post(backendUrl + endpoint, data, headers)
        callback(response)
    } catch (err) {
        console.log("Err in delete axios " + endpoint +" call: " + err.message)
        if(err.response.status === 404) callback(err.response)
    }
}



export {getAuthData, noAuthGet, noAuthPostWHeader,addAccessTokenInterceptor, postAuthData, postDeleleAuthData, editCommentAuth, noAuthCitiesGet, noAuthPost, noAuthGetTest}