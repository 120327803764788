import React from "react";
import { postAuthData} from '../../../utils/axiosCalls';
import {config} from '../../../config';
import { useState } from 'react';
import { Loader } from "../../../utils/loader";
// import { Navigate } from 'react-router';

let ConfirmEdit = (data, ogPass, mapData) => {
    let [postFail, setPostFail ] = useState(null);
    let [isUploading, setIsUploading] = useState(false);
    let objKeys = Object.keys(data.data);
    let callback = data.editCallback;
    // let [loading, setLoading] = loadin
    // let successCallback = data.successCallback;
    // let failedPostCount = 0;
    let capStr = (str = null) => str !== null || str !== undefined ? str[0].toUpperCase() + str.split('').map((char, i) => i !== 0 ? char : null).join('') : str;


    let confirmHandle = () => {
        // calling update endpoint in backend
        // if success, returns {status: 'success', returned: }
        // else {status: 'error', message:}
        setIsUploading(true)

        let proccessedWithNewLocation = data.data
        if(objKeys.includes("birthPlace") && data.mapData.birthObj) {
            proccessedWithNewLocation.birthPlace = coordObjToString(data.mapData.birthObj)
        }
        if(objKeys.includes("burialPlace") && data.mapData.deathObj) {
            proccessedWithNewLocation.burialPlace = coordObjToString(data.mapData.deathObj)
        }
        postAuthData(`/edit/lovedOne`, {obj: {data: proccessedWithNewLocation}}, null, (res) => {
            if(res.data.status === 'success') {
                setIsUploading(false)
                setPostFail(false)
                // successCallback(res.returned)
            } else if(res.data.status === 'error'){
                setIsUploading(false)
                setPostFail(true)
                console.log("Error Updating: " + res.message)
            }
        })
    }

    let ifEmpty = (i, key, data) => typeof data !== "boolean" ? (data[0] ? `${i}. ${capStr(key).split(/(?=[A-Z])/).join(' ')}: ${data}` : `${i}. Removing "${capStr(key).split(/(?=[A-Z])/).join(' ')}" line.`) : `${i}. ${capStr(key)}: ${String(data)}`
    
    let coordObjToString = (obj) => {
        return `${obj.place}#_#${obj.lat}#_#${obj.lng}`
    }

    let comfirmUpdate = () => {
        return (
            <div className="confirmBlock">
                <p className="confirmBlockTitle">Confirm change details</p>
                <br/>
                <div className='changesContainer' style={{ marginTop: '1rem'}}>
                    {objKeys.map((key, index) => {
                        if(key === 'qrLock' && ogPass !== null) return <p className="confirmBlockChanges" key={key}>{index +1}. Password: {data.ogPass}</p>
                        if(key !== '_id') {
                            // if boolean -> the test doesnt show on confirm change modal
                            return <p className="confirmBlockChanges" key={key}>{ifEmpty(index + 1, key, data.data[key])}</p>
                        }
                        return null;
                    })}
                </div>
                <div>
                    <br/>
                    <br/>
                    <p className="confirmBlockQuestion">Do you want to confirm changes that are about to be made?</p>
                    <div className="editConfirmBtnsCont">
                        <button className='aloBtn' type="button" onClick={() => callback(data.data)}>Cancel</button>
                        <button className='aloBtn' type="button" onClick={() => confirmHandle()}>Confirm</button>
                    </div>
                </div>
            </div>
        )
    }

    let failedPost = () => {
        return (
            <div className="confirmBlock">
                <p className="confirmBlockTitle">Failed</p>
                <p className="marginEditPost">Posting update to the database has failed.</p>
                <p className="marginEditPost"> Please check your inputs and try again.</p>
                <button className='aloBtn' type="button" onClick={() => callback(data.data)}>Go Back</button>
            </div>
        )
    }

    let successPost = () => {
        return (
            <div className="confirmBlock">
                <p className="confirmBlockTitle">Success</p>
                <p className="marginEditPost">Posting update to the database successful.</p>
                <button className='aloBtn' type="button"onClick={() => window.location.href = config.FRONTEND_URL + '/profile'}>Continue</button>
            </div>  
        )
    }

    return (
        <div className="homeContainer">
            <div className="confirmContainer">
                {postFail === null ? comfirmUpdate() : postFail === false ? successPost() : failedPost()}
            </div>
            {isUploading && <Loader />}
        </div>
    )
}

export default ConfirmEdit;