export function convertDate(date) {
    let months = {
        "01": "January",
        "02": "Febuary",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        "10": "October",
        "11": "November",
        "12": "December"
    }
    let windowWidthGT = window.innerWidth > 800
    date = new Date(date);
    var convertedDate = date.toISOString().slice(0,10);
    // now rever from year-month-day to day-month-year
    let converted = convertedDate.split('-').reverse()
    converted[1] = windowWidthGT ? months[converted[1]] : months[converted[1]].slice(0,3)
    return converted.join('/')
}