

const width = 400; // We will scale the photo width to this
let height = 0; // This will be computed based on the input stream

// |streaming| indicates whether or not we're currently streaming
// video from the camera. Obviously, we start at false.

let streaming = false;
let takePic = true;

// The various HTML elements we need to configure or control. These
// will be set by the startup() function.

let video = null;
let canvas = null;
let startbutton = null;

let localstream;

export let addVideoStream = () => {
  navigator.mediaDevices
  .getUserMedia({ video: true, audio: false })
  .then((stream) => {

    localstream = stream;
    video.srcObject = localstream;
    video.play().catch((e)=>{
        /* error handler */
        console.log(e.message);
     });
  })
  .catch((err) => {
    console.error(`An error occurred: ${err}`);
  });
}

export function startup() {
  video = document.getElementById("video");
  canvas = document.getElementById("canvas");
  startbutton = document.getElementById("startbutton");
  
  if (!navigator.webkitGetUserMedia && !navigator.mozGetUserMedia && !navigator.mediaDevices.getUserMedia) {
    alert('WebRTC is not available in your browser.');
  } else {
    addVideoStream()
  }

  video.addEventListener(
    "canplay",
    (ev) => {
      if (!streaming) {
        height = video.videoHeight / (video.videoWidth / width);

        // Firefox currently has a bug where the height can't be read from
        // the video, so we will make assumptions if this happens.

        if (isNaN(height)) {
          height = width / (4 / 3);
        }

        video.setAttribute("width", width);
        video.setAttribute("height", height);
        canvas.setAttribute("width", width);
        canvas.setAttribute("height", height);
        streaming = true;
      }
    },
    false
  );

  startbutton.addEventListener("click", function(e) {
      e.preventDefault();
      let btnText = ['Take Photo', 'Cancel']
      if(takePic) {
        takepicture()
        video.style.display = 'none';
        canvas.style.display = 'block';
        startbutton.textContent = btnText[1]
      } else {
        addVideoStream()
        video.style.display = 'block';
        canvas.style.display = 'none';

        clearphoto();

        startbutton.textContent = btnText[0]
      }
      takePic = !takePic;

    },
    false
  );

  // clearphoto();
}

// Fill the photo with an indication that none has been
// captured.

function clearphoto() {
  const context = canvas.getContext("2d");
  context.fillStyle = "#AAA";
  context.fillRect(0, 0, canvas.width, canvas.height);

  const data = canvas.toDataURL("image/png");
  video.setAttribute("srcObject", data);

}

// Capture a photo by fetching the current contents of the video
// and drawing it into a canvas, then converting that to a PNG
// format data URL. By drawing it on an offscreen canvas and then
// drawing that to the screen, we can change its size and/or apply
// other changes before drawing it.

function takepicture() {
  // let vid = document.getElementById("video");

  const context = canvas.getContext("2d");
  // if (width && height) {
    canvas.width = width;
    canvas.height = height;
    context.drawImage(video, 0, 0, width, height);
    const data = canvas.toDataURL("image/png");
    video.setAttribute("src", data);
  // } 
}


export function vidStop() {
  let vid = document.getElementById("video");
  vid.pause();
  vid.src = "";
  localstream.getTracks()[0].stop();
  localstream = null;
}

export function resizeMe(img, {max_width, max_height}) {
  
  var canvas = document.createElement('canvas');

  var width1 = img.width;
  var height1 = img.height;
  console.log('main: ', width1, height1)

  // calculate the width and height, constraining the proportions
  let resizeImage = function(x,y,percentage,minimum) {
    if (x > y) {
        let ratio = y/x;
        let rx = (x-minimum)*(percentage/100);
        let ry = (y-minimum*ratio)*(percentage/100);
        return [rx+minimum,ry+minimum*ratio];
    } else {
      let ratio = x/y;
      let rx = (x-minimum*ratio)*(percentage/100);
      let ry = (y-minimum)*(percentage/100);
      return [rx+minimum*ratio,ry+minimum];
    }
}
  let [x, y] = resizeImage(width1, height1, 20, 600)
  // resize the canvas and draw the image data into it
  canvas.width = x;
  canvas.height = y;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, x, y);
  
  // preview.appendChild(canvas); // do the actual resized preview
  
  return canvas.toDataURL("image/jpeg",0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)

}