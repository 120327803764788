import { useAuth0 } from "@auth0/auth0-react";
import React from "react";


export let CallLogin = () => {
    const { loginWithRedirect} = useAuth0();

    localStorage.setItem("pathname", window.location.pathname)
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    })
}