import { useAuth0 } from "@auth0/auth0-react";
import React, {useEffect, useState} from 'react';
import {getAuthData} from '../../utils/axiosCalls';
import PersonCard from '../People/personCard/personCard';
import PersonCardLrg from '../People/personCard/personCardLrg/personCardLrg';
import ConfirmEdit from './confirmEdit/confirmEdit';
import EditView from './edit/edit';
import {Loader} from '../../utils/loader';
import {config} from "../../config";
import { NavLink } from "react-router-dom";



import './profile.css';

let Profile = () => {
    let [myLO, setMyLO] = useState(null);
    let [allLO, setAllLO] = useState(null);
    let [loading, setLoading] = useState(false);
    let [edit, setEdit] = useState(false);
    let [editId, setEditId] = useState(null);
    let [confirmEdit, setConfirmEdit] = useState(false);
    let [editData, setEditData] = useState(null);
    let [personViewBool, setPersonViewBool] = useState(false);
    let [personIdToView, setPersonIdToView] = useState(null);
    let [AllAloBool, setAllAloBool] = useState(false);
    let [tokenCount, setTokenCount] = useState(0)
    let [ogPass, setOgPass] = useState(null)
    let [editExit, setEditExit] = useState("openEdit")
    let [mapChooseType, setMapChooseType] = useState(null)
    let [birthObj, setBirthObj] = useState({})
    let [deathObj, setDeathObj] = useState({})

    const { user, isAuthenticated, isLoading} = useAuth0();

    useEffect(() => {
        let lovedOnesUrl = '/api/myLovedOnes';
        setLoading(true)
        // Call gets users created loved ones (requires Auth)
        getAuthData(lovedOnesUrl, (res) => {
            if(res.data) {
                res.data.data && setMyLO(res.data.data)
                res.data.extra && setAllLO(res.data.extra)
                
                setTokenCount(res.data.token)
            } 
        })

        setLoading(false)
    }, [])

    let mapChoose = (e, type="death") => {
        e.preventDefault();
        if(type === "death") {
            setMapChooseType("death")
        } else if(type === 'birth') {
            setMapChooseType("birth")
        }
    }

    let mapCoordProccessor = (isOK, obj) =>{
        if(!isOK) return 

        setMapChooseType(null)
        let {type, lng, lat, place} = obj

        if(type === "death") {
            setDeathObj({lat, lng, place})
        } else if(type === 'birth') {
            setBirthObj({lat, lng, place})
        }
    }

   

    function noLovedOnes() {
       
        return (
            <div className='noLOContainer'>
                <div className="noLODiv">
                    <p>You have no current loved ones set under your account</p>
                    {isAuthenticated && <NavLink className={'addLink'} to="/lovedOne/new">Add Person</NavLink>}
                </div>
            </div>
        )
    }

    let scrollDivToTop = () => {
        let myDiv = document.getElementsByClassName('lrgCardCont')[0];
        myDiv.scrollTop = 0;
    }

    function mainView() {
        return (
            <div className="profContainer">
                <br/>
                <div className="firstCont blkBorder">
                    {isAuthenticated && <img className='profilePic' src={user.picture} alt={user.name}/>}
                    {isAuthenticated && (
                        <div className="infoCont">
                            <h3>Name: {user.name.split('@')[0]}</h3>
                            <p>Email: {user.email}</p>
                            <p>ALO Tokens: {tokenCount}</p>
                        </div>
                    )}
                    
                </div>
                <div className="myPosts">
                    {myLO && <div className="titleHolder"><p className="lovedOnesTitleText blkBorder">My Loved Ones</p></div>}
                    <br></br>
                        {((!loading && !myLO) || !myLO[0]) && noLovedOnes()}

                    <div className="lovedOnesCont">
                        {myLO && myLO.map(post => {
                            return (
                                <div className='smallCardHolder' key={post._id}>
                                    <PersonCard key={post._id} props={post} profileCallback={(id) => {
                                        setEdit(true);
                                        setEditId(id);
                                    }} profView={(id) => {
                                        setPersonIdToView(id)
                                        // add id to params
                                        setPersonViewBool(true);
                                        setAllAloBool(false);
                                    }} deleteCall={(id) => {
                                        let url = `/api/remove/${id}`;
                                        let passedOn = `/api/passedOn/${post._id}`
                                        let ans = window.confirm('Are you sure you want to delete this?');
                                        let checker = () => getAuthData('/api/rabbithole', (res) => res.return)
                                        
                                        if(ans) {
                                            if(checker() === true ) {
                                                getAuthData(passedOn, (res) => {
                                                    
                                                    if(res.data.status === 'success') {
                                                        window.alert('success in removal')
                                                        
                                                    } else {
                                                        window.alert('Error deleting user');
                                                        console.log(res.data.err)
                                                        
                                                    }
                                                })
                                            } else {
                                                // else if for other delete
                                                getAuthData(url, (res) => {
                                                    console.log(res)
                                                    
                                                    if(res.data.status === 'success') {
                                                        // console.log('success in removal')
                                                        window.location.reload()
                                                    } else {
                                                        window.alert('Error deleting user, please send us an email by going to about page and click email us. Refference your name and the LoveOnes name.')
                                                        
                                                    }
                                                })
                                            }
                                        }
                                    }}/>
                                </div>
                            )
                            
                        })}
                        
                    </div>
                </div>
                {allLO && <p className="lovedOnesTitleText blkBorder">All Loved Ones</p>}

                

                {allLO && <div className="lovedOnesCont">
                {allLO && allLO.map(post => {
                            return (
                                <div className='smallCardHolder' key={post._id}>
                                    <PersonCard key={post._id} props={post} 
                                    profileCallback={(id) => {
                                        setEdit(true);
                                        setEditId(id);
                                    }} 
                                    sec={true}
                                    profView={(id) => {
                                        setPersonViewBool(true);
                                        setAllAloBool(true);
                                        setPersonIdToView(id)
                                        
                                        console.log(id)
                                    }} 
                                    deleteCall={(id) => {
                                        
                                        let passedOn = `/api/passedOn/${id}`
                                        let ans = window.confirm('Are you sure you want to delete this?');
                                        // 
                                        // request to rabbit hole not returning true in turn not running below if statement checker() === 'true'
                                        // 
                                        
                                        getAuthData('/api/rabbithole', (res) => {
                                            if(ans) {

                                                if(res.data.return === 'true' ) {
                                                    console.log('working now')
                                                    getAuthData(passedOn, (resp) => {
                                                        console.log(resp)
                                                        
                                                        if(resp.data.status === 'success') {
                                                            window.alert('success in removal')
                                                            
                                                        } else {
                                                            window.alert('Error deleting user');
                                                            console.log(resp.data.err)
                                                            
                                                        }
                                                    })
                                                } 
                                            }
                                        })
                                    }
                                    
                                }/>
                                </div>
                            )
                            
                        })}
                </div>}


            </div>
        )
    }

    let exitHandle = (bool=false) => {
        // false = closes modal for continue
        // true = confirms close and exit to profile
        // string close = opens modal for confirmation
        if(bool === false) {
            setEditExit('openEdit')
        } else if(bool === true) {
            setEdit(false);
            setEditId(null)
            setEditExit("openEdit")
        } else if (bool === 'close'){
            setEditExit("openModal")
        }
    }

    let viewExit = () => {
        setPersonViewBool(false);
        setPersonIdToView(null);
        // window.location.href = window.location.origin + '/profile'
    }

    let editSubmitClicked = (personObj, ogPasswd) => {
        if(ogPasswd !== null) setOgPass(ogPasswd)
        personObj['_id'] = editId;
        setEditData(personObj);
        setConfirmEdit(true);
    }

    let reEditCallback = (data) => {
        setEditData(null);
        setConfirmEdit(false);
    }

    let editSubmitSuccess = () => {
        setEdit(false);
        setEditId(null);
        setEditData(null);
        setConfirmEdit(false);
    }

    if (isLoading || loading) {
        return <Loader/>
    }

    if(!edit) {
        if(personViewBool) {
            let person = [];
            let filterIDAndReturnObj = (lovedOnes) => {
                person = lovedOnes.filter(lovedOne => lovedOne._id === personIdToView)
            }
            if (AllAloBool) filterIDAndReturnObj(allLO) 
            else filterIDAndReturnObj(myLO)
            return <div className="lrgCardCont"><PersonCardLrg  props={person} user={user} callback={() => {viewExit()}} onbegin={scrollDivToTop}/></div>
        } else {

            if(!myLO && !loading) return <Loader/>
            if(myLO)  return <div className="profileCont">{mainView()}</div>
            else if(!myLO && !loading) return noLovedOnes()
        }
    } else if(!confirmEdit) {
        return <div className="editViewParent"> {EditView(myLO, editId, exitHandle, editSubmitClicked, null, editExit, {
            mapChoose,
            mapCoordProccessor,
            birthObj,
            deathObj,
            mapChooseType
        })} </div>
    } else {
        return <div className="editViewParent"><ConfirmEdit data={editData} editCallback={reEditCallback} successCallback={editSubmitSuccess} ogPass={ogPass} mapData={{birthObj, deathObj}}/></div>
    }
}

export default Profile;