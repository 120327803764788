import React from "react";
import { postDeleleAuthData, postAuthData } from "../../../../../../utils/axiosCalls";
import extentionWhitelist from '../../../../../../utils/extentionWhitelist';
import './index.css';

class UploadFile extends React.Component{
    constructor(props) {
        super(props);
        
        this.open = false;
        this.name = props.name;
        this.email = props.email;
        this.refresher = props.refresher;
        this.id = props.loid;
        this.setter = props.setter;


    }

    UploadAction() {
        let docsInput = document.querySelector('.cardFileUploader');
        let skippedDocs = [];
        let docsToSave = [];
        // console.log('Now Uploading')

        let addFilesToFormData = (files) => {
            let name = this.name; //filename
            let formDT = new FormData();
            let underscoreSplitter = (name) => name.replaceAll('_', ' ')
            Object.keys(files).map((file, i) => {
                if(extentionWhitelist(docsInput.files[file].name)) {
                    // console.log(`${email}_${name.includes(' ') ? name.split(' ').join('$') : name}_${docsInput.files[file].name}`)
                    formDT.append(`${name.includes(' ') ? name.split(' ').join('$') : name}_${underscoreSplitter(docsInput.files[file].name)}`, files[file]);
                    docsToSave.push(docsInput.files[file].name)
                } else {
                    skippedDocs.push(docsInput.files[file].name)
                }
    
                return null;
            })
            // checks if formDT has inner data 
            // if not return null
            return !formDT.entries().next().done ? formDT : null
        }

        let formDT = addFilesToFormData(docsInput.files)
        // console.log(formDT)
        if(!formDT) {
            alert('File Not being uploaded as extention is incorrect')
        } else {
            // console.log('Success')
            postAuthData(`/api/fuploader/${this.id}`, formDT, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }, (res) => {
                console.log(res)
                if(res.data.status === 'success') {
                    console.log('Upload Success')

                    this.setter(res.data.pointer)
                    
                }
                this.exitHandle()
                if(skippedDocs[0]) {
                    window.alert(`Documents skipped due to filetype:\n\n${skippedDocs.join('\n')}\n\n\nFiles Uploaded:\n\n${docsToSave.join('\n')}`)
                }
              })
        }
        // console.log(docsInput.files)
    }

    clickHandle() {
        let currentState = this.open;
        this.open = !this.open;

        let inputContainer = this.createInputAndLabel()
        let btnEl = document.querySelector('.uploadFileCont');
        currentState ? btnEl.removeChild(document.querySelector('.fileInputContainer')) : btnEl.append(inputContainer)

    }

    createInputAndLabel() {
        let container = document.createElement('div');
        let inputEl = document.createElement('input');
        inputEl.onchange = (e) => this.inputChangeHandler(e);
        container.classList.add('fileInputContainer');
        inputEl.classList.add('cardFileUploader');
        inputEl.setAttribute('type', 'file')
        inputEl.setAttribute('accept', ".docx, .txt, .pdf")
        inputEl.setAttribute('multiple', 'true')
        container.append(inputEl)
        return container
    }

    exitHandle() {
        let btnContainer = document.querySelector('.uploadFileCont');

        let readyBtn = document.querySelector('.uploadFileBtnReady');
            let btn = document.querySelector('.uploadFileBtn');

            readyBtn.style.display = 'none';
            btn.style.display = "flex";


            // btnContainer.removeChild(document.querySelector('.fileUploadExit')) 
            btnContainer.removeChild(document.querySelector('.fileInputContainer')) 
            
            this.open = !this.open;

    }

    inputChangeHandler = (e) => {
        
        let readyBtn = document.querySelector('.uploadFileBtnReady');
        let btn = document.querySelector('.uploadFileBtn');

        btn.style.display = 'none';
        readyBtn.style.display = "flex";

        let btnContainer = document.querySelector('.fileInputContainer');
        // btnContainer.removeChild(document.querySelector('.fileUploadExit')) 

        let exitBtn = document.createElement('p');
        exitBtn.classList.add(`fileUploadExit`)
    
        exitBtn.textContent = 'X';
        exitBtn.style.position = 'relative';
    
        exitBtn.style.zIndex = '5';
        exitBtn.style.textAlign = 'right';
        exitBtn.style.marginRight = '1rem';
        exitBtn.style.padding = '5px';
        exitBtn.style.cursor = 'pointer';
        exitBtn.onclick = () => {
            this.exitHandle()
        }

        let inputContainerHasExitBtn = btnContainer.lastChild.classList.contains('fileUploadExit')
        if (!inputContainerHasExitBtn) {
            btnContainer.append(exitBtn)
        }
        
    } 

    render() {
        return (
            <div className='uploadFileCont'> 
                <p className='uploadFileBtn imgBtnStyle' onClick={(e) => this.clickHandle()}>Upload File</p>
                <p className='uploadFileBtnReady imgBtnStyle' onClick={(e) => this.UploadAction()}>Upload Now</p>
            </div>
        )   
    }
}   

export default UploadFile;