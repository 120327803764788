import React, { useEffect, useState, useCallback } from "react";
import "./index.css";
import MapComponent from "./mapComponent.js";

import { GeolocatorInputContainer } from "./geolocatorInput/geolocatorInput.js";

let GoogleMap = () => {
  let [showMap, setShowMap] = useState(false);
  let [coord, setCoord] = useState([null, null]);
  let [err, setErr] = useState(false);
  let [errString, setErrorString] = useState("");

  let getCurrentWidthProps = useCallback(() => {
    let width = window.innerWidth;
    let showMapAndSmallScreen = showMap && width < 600;
    let showMapAndLargeScreen = showMap && width > 600;
    return { showMapAndSmallScreen, showMapAndLargeScreen };
  }, [showMap]);

  window.addEventListener("resize", (e) => {
    let geoContainer = document.querySelector(".contentContainerGeo");
    let inputCont = document.querySelector(".mapUtil");

    let { showMapAndSmallScreen, showMapAndLargeScreen } =
      getCurrentWidthProps();

    if (showMapAndSmallScreen) {
      if (!geoContainer.classList.contains("hide")) {
        geoContainer.classList.add("hide");
      }
      inputCont.style.height = "15%";
    } else if (showMapAndLargeScreen) {
      if (geoContainer.classList.contains("hide")) {
        geoContainer.classList.remove("hide");
      }

      inputCont.style.height = "100%";
    }
  });

  useEffect(() => {
    let inputCont = document.querySelector(".mapUtil");
    let geoContainer = document.querySelector(".contentContainerGeo");
    let { showMapAndSmallScreen, showMapAndLargeScreen } =
      getCurrentWidthProps();

    if (showMapAndSmallScreen) {
      geoContainer.classList.add("hide");
      inputCont.style.height = "15%";
    } else if (showMapAndLargeScreen) {
      inputCont.style.height = "100%";
    } else {
      inputCont.style.height = "100%";
    }
  }, [getCurrentWidthProps]);

  let coordinateCallback = (err, coord) => {
    let geoContainer = document.querySelector(".contentContainerGeo");

    let { showMapAndSmallScreen } =
      getCurrentWidthProps();

    let noGPS = " Error the image you used doesnt have any GPS metadata.";
    let notImageFile = "The File you tried to upload is not an image.";
    if (err) {
      if (coord === "Not Image File") {
        setErrorString(notImageFile);
      } else {
        setErrorString(noGPS);
      }
      console.log("This image has no gps data: ");
      let inputCont = document.querySelector(".mapUtil");
      inputCont.style.height = "100%";
      setErr(true);
      if(showMapAndSmallScreen) {
        if (geoContainer.classList.contains("hide")) {
          geoContainer.classList.remove("hide");
        }
      }
      setTimeout(() => {
          setErr(false);
      }, 3000);
    } else {
      setCoord(coord);
      setShowMap(true);
    }
  };

  let errorComponent = () => {


    return (
      <p className="geoError">
        {errString}
        <br />
        <br />
        Allowed types: jpg, jpeg, png
        <br />
        <br />
        Please try again with another.
      </p>
    );
  };

  return (
    <div className="container" >
      <div className="gmapParent"style={{padding: "10px", boxSizing: "border-box"}}>
        <div className="mapHeader">
          <h2>Welcome to the Geolocator</h2>
        </div>
        <div className="mapParent">
          <div className="mapUtil" style={{padding: "10px"}}>
            <div className="contentContainerGeo">
              <h4 className="mb-1">
                Capture the geolocation of your loved ones resting place
              </h4>
              <p className="mb-1">
                Here you can use an image you have taken of your loved ones
                gravestone and we can capture the coordinates from that image,
                store that and allow family members to find your loved ones
                grave if they have access to the lovedones page.
              </p>
              <p className="mb-1">
                The reason for this is to get better coorrdinates for the final
                resting place of out loved ones, allowing others to better find
                it if needed.
              </p>
            </div>

            {err && errorComponent()}
            <div>
              <button
                style={{ marginBottom: "1rem", padding: "2px" }}
                onClick={() => {
                  let geoContainer = document.querySelector(".contentContainerGeo");
                  if(showMap && window.innerWidth < 600) geoContainer.classList.remove('hide')
                  else if(!showMap && window.innerWidth < 600){ geoContainer.classList.add('hide')}
                  setShowMap(!showMap)

                }}
              >
                {showMap ? "Hide" : "Show"} Map
              </button>
              {GeolocatorInputContainer(coordinateCallback, setShowMap)}
            </div>
          </div>

          {showMap && (
            <div style={{width: "70%", height: "60%"}}>
              <MapComponent
              lat={coord[0]}
              lng={coord[1]}
              callback={(coords) => {
                console.log("New coords: ", coords);
              }}
              mapData={null}
            />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoogleMap;
