import React from "react";
import {useState, useEffect} from 'react';
import imageUploaded from '../../utils/imageToBase64';
import {iterateObjAndClean} from '../../utils/sanitizerFuncs';
import extentionWhitelist from '../../utils/extentionWhitelist';
import Compressor from 'compressorjs';
import {useNavigate} from "react-router-dom";
import {Loader} from "../../utils/loader.js"

// import formUploadClass from '../../utils/formUploadClass/formUploadClass';


import './lovedOne.css';
import AdderForm from '../Forms/adderForm';
import AdditionalInfoForm from '../Forms/additionalInfoForm.jsx';
import { getAuthData, postAuthData } from '../../utils/axiosCalls';
import {config} from '../../config';
import sha256 from "js-sha256"


let LovedOneAdder = () => {
    let [userInfo, setUserInfo] = useState(null);
    let [tokens, setTokens] = useState(0);
    let [loading, setLoading] = useState(false)
    let [coords, setCoords] = useState({})

    let navigate = useNavigate()

    let Flogger = {
        image:{
            count: 0,
            status: '',
            error: ''
        },
        doc: {
            count: 0,
            status: '',
            error: ''
        },
        data: {
            lovedOne:'',
            status: '',
            error: ''
        }
    }

    // let Flogger = new formUploadClass()
    
    useEffect(() => {
        
        try {
            async function getState() {
                await getAuthData('/profile', (value) => {
                    // axios call returns false if error is caught
                    // possibly not logged in
                    if(!value) console.log('Error fetching profile data')
                    else {
                        setUserInfo(value.data.email)
                        setTokens(value.data.token)
                        if(Number(value.data.token) === 0 || undefined) {
                            navigate('/purchase')
                        } 
                    }
                })
            }
            getState()
        } catch (error) {
            console.log(error);
        }
            
    }, [navigate])

    let sendFiles = (formDT, skippedDocs, docsToSave) => {
        return new Promise(resolve => {
            let status = [];

        // if form data is not null, send data to server
        // may be empty because whitelist did not add excluded filetypes to formDT
        // -------------------------------------------------------------------------------------------------------------------------------------------------------------
        // changed below to use the new postAuthData function
        if (formDT !== null) { 
            postAuthData('/api/fuploader/createAlo', formDT, { headers: {
                "Content-Type": "multipart/form-data",
                },
            }, (res) => {
                Flogger.doc.status = res.data
                if(skippedDocs[0]) {
                    window.alert(`Documents skipped due to filetype:\n\n${skippedDocs.join('\n')}\n\n\nFiles Uploaded:\n\n${docsToSave.join('\n')}`)
                }
                console.log(res)
                resolve()
            }) 
        
        } else {
            window.alert('Please only upload allowed filetypes to the additional files input\n\nAllowed files: .txt .docx\n\n' + skippedDocs.join('\n'))
            resolve()
        }
            Flogger.doc.status += status
        })
    }

    let getDocs = (name) => {
        return new Promise(res => {
            let docsInput = document.querySelector('#additionalDocs');
            let skippedDocs = [];
            let docsToSave = [];
            if(!docsInput.files[0]) {
                console.log('No additional documents set for upload.')
                return;
            };
            // console.log(docsInput.files.length + " documents set for upload.");
            Flogger.doc.count =docsInput.files.length;

            // console.log(userInfo , docsInput.files)
            let addFilesToFormData = (files) => {
                let formDT = new FormData();
                Object.keys(files).map((file, i) => {
                    if(extentionWhitelist(docsInput.files[file].name)) {
                        formDT.append(`${name.includes(' ') ? name.split(' ').join('$') : name}_${docsInput.files[file].name}`, files[file]);
                        docsToSave.push(docsInput.files[file].name)
                    } else {
                        skippedDocs.push(docsInput.files[file].name)
                    }

                    return null;
                })
                // checks if formDT has inner data 
                // if not return null
                return !formDT.entries().next().done ? formDT : null
            }
            let formDT = addFilesToFormData(docsInput.files)

            sendFiles(formDT, skippedDocs, docsToSave).then(() => {
                res()
            });
        })
    };



    let getInputData = (className) => document.getElementsByClassName(className)[0].value ? document.getElementsByClassName(className)[0].value : null;
        
    let preSubmitCheck = (e) => { 

        setLoading(true)
        // --------------------------------------------------------------------------------------
        // check name and mother father for spaces indicting a first and lastname where entered

        let nameInputs = ['lovedOnesNameInput', 'mothersNameInput', 'fathersNameInput'];
        let checkFNameAndLName = (str) => {
            if(str) {

                let name = str.split(' ');
                if(name[1]) return true
                else return false
            } else return false
        }

        //error checking for str is null err
        let checkedNames = nameInputs.map(nameInput => getInputData(nameInput)).map(str => { return checkFNameAndLName(str)});
        let emptyNameInputs = []
        checkedNames.map((bool, i) => {
            if(!bool) emptyNameInputs.push(nameInputs[i])
            return null
        })
        // console.log('name inputs: ', emptyNameInputs)

        if(emptyNameInputs[0]) {
            window.alert('name inputs are not filled correctly, please make sure the names of your loved one, their mother and faather are both first name aand last name.');
            // must add error text to the inputs that aare not filled correctly and return null so it does not continue
            setLoading(false)
            return null;
        }

        // checking name inputs end
        // -----------------------------------------------------------------------------


        let runSubmit = false
        let linkClassNames = ['youtube', 'twitter', 'insta', 'facebook', 'imageInput'];
        let linkBool = [false, false, false, false];
        let linkRegex = '';
        let LinkBoolManipulator = (link, bool) => linkBool[linkClassNames.indexOf(link)] = bool;
        let linkRegexCheckAndmanipulate = (link, linkStr) => link.match(linkRegex) ? LinkBoolManipulator(linkStr, true) : console.log('did not work: ' + linkStr);
        let emptyInputs = [];
        // -----------------------------------------------------------------
        // Form check for required values

        // check links + regex for validation

            linkClassNames.map(linkStr => {
                
                let link = getInputData(linkStr);
                if(link === null) return null;
                else {
                    LinkBoolManipulator(linkStr, true);
                }
                if(linkStr === 'youtube') {
                    linkRegex = /(https?:\/\/)?(www\.)?youtube\.com\/(channel|user)\/[\w-]+/
                    console.log(linkRegex, link, linkStr)
                    linkRegexCheckAndmanipulate(link, linkStr);
                }
                
                return null;
            })

        // links End
        let filledLinkInputs = linkBool.map((bool, i) => {
            if(bool) linkBool[i] = linkClassNames[i];
            return linkBool[i]
        }).filter(link => link !== false && link)

        linkClassNames.map(linkStr => {
            if(!filledLinkInputs.includes(linkStr)) {
                // if input name is not found then its empty so add to array for verification
                // that this is intentional
                emptyInputs.push(linkStr)
            }
            return null
        })
        
        // console.log(`Empty inputs include: ${emptyInputs.join(', ')}`);
        // -----------------------------------------------------------------

        let socialInputsStr = `Empty social media inputs include: ${emptyInputs.join(', ')}\n\n`;
        let fullNameInputsStr = `Please Enter fullnames for: ${emptyNameInputs.join(', ')}\n\n`

        // if social inputs or name inputs have a value, then confirm they want to continue, if there are no errors then just continue the process.
        runSubmit = emptyInputs[0] || emptyNameInputs[0] ? window.confirm(`${emptyInputs[0] ? "Empty Links: " + socialInputsStr + '\n\n' : ''}${emptyNameInputs[0] ? 'Lastname not Provided: ' +fullNameInputsStr + '\n\n': ''}\n continue to upload?`): true;
        if(runSubmit) onSubmit(e);
        else {
            console.log('did not run submit due to errors');
            setLoading(false)
        }
        
    }
    const getCoords =(coordsObj) => {
        // gets coords from adderForm on update and saves to state for upload
        if((coords.birthObj !== undefined || coords.deathObj !== undefined) && (coordsObj.birthObj.place === coords.birthObj.place && coordsObj.deathObj.place === coords.deathObj.place)) return
        setCoords(coordsObj)
    }
    
    const onSubmit = (e) => {
        let imageFiles = document.getElementById('file');
        let publicCheckbox = document.querySelector('.pCheckbox').checked;
        let personObj = {}
        if(publicCheckbox) {
            let pass = document.querySelector('#qrPass').value;
            var hash = sha256.create();
            hash.update(pass);
            let hashed = hash.hex()
            personObj.qrLock = hashed
        } else {
            personObj.qrLock = ""
        }
        
        
        // personObj.creator = userInfo;
        
        let person = [];
        
        for(let i = 0; i < e.target.length; i++) {
            if(i === 5 || i === 7) continue;
            person.push(e.target[i].value);
        }

        let coordObjToString = (obj) => {
            return `${obj.place}#_#${obj.lat}#_#${obj.lng}`
        }

        person.push(imageFiles.files);
        personObj.name = person[0].trim();
        personObj.dob = person[1]
        personObj.dod = person[2]
        personObj.bio = person[3]
        personObj.birthPlace = coordObjToString(coords.birthObj);
        personObj.burialPlace = coordObjToString(coords.deathObj);
        personObj.mother = person[6].trim();
        personObj.father = person[7].trim();
        personObj.siblings= [];
        personObj.children = [];
        personObj.youtubeLink = getInputData('youtube');
        personObj.twitterLink = getInputData('twitter');
        personObj.instagramLink = getInputData('insta');
        personObj.facebookLink = getInputData('facebook');
        personObj.privateInfo = publicCheckbox.toString();

         // if there are files(images) in the file input 
        // then run the function to process and post data to endpoint

        Flogger.data.lovedOne = personObj.name
        
        // code below finds index for the kids amount input value
        let kidsCountInt = person[8 + 1 + parseInt(person[8])];
        
        for(let i = 0; i < person[8]; i++) {
            personObj.siblings.push(person[i + 9].trim())
        }
        
        
        for(let i = 0; i < kidsCountInt; i++) {
            // 7 is sibling index + 2 and plus the sibling count is the first index of kids info
            // plus i to increment value and iterate through.
            personObj.children.push(person[i + 8 + 2 + parseInt(person[8])].trim());
        }
        
        

        // cleans current object values of harmful xxs syntax
        let cleanedPersonObj = iterateObjAndClean(personObj);


        // ----------------------- Saves Images --------------------------------------

        let saveImagesAndDocsFirst = () => {
            return new Promise(resolve => {
                if(imageFiles.files[0]) {
                    const fd = new FormData();
        
                    // Iterate over all selected files
                    Array.from(imageFiles.files).forEach((file, i) => {
                        fd.append(`image${i}`, file);
                    });
        
                    postAuthData(`/api/images/${person[0].trim()}/tyrHOjChBjceq87rbjvC`, fd, {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        }}, (res) => {
                            console.log(res)
                            // this.setState({loading: false})
                            if(res.data.status === 'success') {
                                personObj.images = res.data.pointer.join(',')
                            } else {
                                console.error(res)
                            }

                            getDocs(personObj.name).then(() => {
                                resolve()
                            });
                        }
                    )
                }
            })
        }

    // ----------------------- Saves All Info Data --------------------------------------
    saveImagesAndDocsFirst().then(() => {

        postAuthData('/api/Info', cleanedPersonObj, null, (result) => {
            let {res} = result.data;
            // console.log('res: ', result);
            if(res === 'Success') {
                Flogger.data.status = res
                // on successful post of person data redirect page to people page for viewing
                window.alert('Successfully uploaded loved one.');
                setLoading(false)

                setTimeout(() => {
                    window.location.href = config.FRONTEND_URL;
                }, 3000)
                
            } else {
                // post failed somehow 
                // console.log(result.data)
                Flogger.data.error =res
                setLoading(false)
                window.alert('Loved one failed to upload user data.');
            }
        });
    })

    };

    return (
        <div className='adderBG'>
            <div className='adderContainer'>
                <div className='adderCaption'>
                    <p className="adderTitle">Add a Loved One.</p>
                    <p>Get a QR code for the digital location of the info.</p>
                    <p>Engrave the code in a gravestone for future generations.</p>
                </div>
                <div className='adderDualContainer'>
                    {/* {postSuccess === false && <p>{postError}</p>} */}
                    <form className="formStyle" onSubmit={(e) => { e.preventDefault(); preSubmitCheck(e)}}>
                        <div className='adderDual1'>
                            <p className='formTitle'>Personal Information</p>
                            <AdderForm getCoords={getCoords}/>
                        </div>
                        <div className='adderDual2'>
                            <p className='formTitle'>Other Information</p>
                            <AdditionalInfoForm />
                        </div>
                    </form>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    )
}

export default LovedOneAdder;