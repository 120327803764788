import { useEffect } from 'react';
import React from "react";
import {noAuthGet} from '../../utils/axiosCalls'
import './paginate.css';

function Paginate({pageSet, currPage, maxPerPage=5}) {
   
    useEffect(() => {
        noAuthGet('/access/lovedones/count', (res) => {
            // console.log('total: ', res.data)
            let totalLO = res.data;
            let ppp = maxPerPage;
            let roundUp = (num) => Math.ceil(num)
            const paginationButtons = new PaginationButtons(roundUp(totalLO / ppp), ppp, currPage);
            window.scrollTo(0,0);
            paginationButtons.render()
        })
    }, []);
    // total = total pages
// max = max number shown i.e: what is max of the 10 nums
//current = current num
const pageNumbers = (total, max, current) => {
    const half = Math.round(max/2);
    let to = max;
    
    if(current + half >= total) {
      to = total
    } else if (current > half) {
      to = current + half
    }
    
    let from = to - max;
    return Array.from({length: max}, (_, i) => (i + 1) + from);
  }
  
  // console.log(pageNumbers(100, 10, 58))
  
  class PaginationButtons {
        constructor(totalPages, maxPageVisible, currentPage = 1) {
            let pages = pageNumbers(totalPages, maxPageVisible, currentPage);
            // console.log(pages)
            pages = pages.filter(num => num > 0)
            let currentPageBtn = null;

            const buttons = new Map();
            const fragment = document.createDocumentFragment();

            const paginationButtonsContainer = document.createElement('div');
            paginationButtonsContainer.className = 'pagination-buttons';
            // paginationButtonsContainer.textContent= 'pagination-buttons';
            const disabled = {
                start: () => currentPage === 1,
                prev: () => currentPage === 1,
                next: () => currentPage === totalPages,
                end: () => currentPage === totalPages,
            };

            const createAndSetupButton = (label = '', cls = '', disabled = false, handleClick = () => { }) => {
                const button = document.createElement('button');
                button.textContent = label;
                button.className = `page-btn ${cls}`;
                button.disabled = disabled;
                button.addEventListener('click', event => {
                    handleClick(event);
                    this.update();
                });

                return button;
            };

            const onPageButtonClick = e => currentPage = Number(e.currentTarget.textContent);
            const onPageButtonUpdate = index => btn => {

                btn.textContent = pages[index];

                if (pages[index] === currentPage) {
                    currentPageBtn.classList.remove('active');
                    btn.classList.add('active');
                    currentPageBtn = btn;
                    currentPageBtn.focus();

                }
            };

            buttons.set(
                createAndSetupButton('start', 'start-page', disabled.start(), () => currentPage = 1),
                (btn) => btn.disabled = disabled.start()
            );

            buttons.set(
                createAndSetupButton('prev', 'prev-page', disabled.prev(), () => currentPage -= 1),
                (btn) => btn.disabled = disabled.prev()
            );

            pages.forEach((pageNumber, index) => {
                const isCurrentPage = pageNumber === currentPage;
                const button = createAndSetupButton(pageNumber, isCurrentPage ? 'active' : '', false, onPageButtonClick);

                if (isCurrentPage) {
                    currentPageBtn = button;
                }

                buttons.set(button, onPageButtonUpdate(index));
            });

            buttons.set(
                createAndSetupButton('next', 'next-page', disabled.next(), () => currentPage += 1),
                (btn) => btn.disabled = disabled.next()
            );

            buttons.set(
                createAndSetupButton('end', 'end-page', disabled.end(), () => currentPage = totalPages),
                (btn) => btn.disabled = disabled.end()
            );

            buttons.forEach((_, btn) => {
                fragment.appendChild(btn);
            });

            

            this.update = (newPageNumber = currentPage) => {
                currentPage = newPageNumber;
                pageSet(Number(currentPage));
                pages = pageNumbers(totalPages, maxPageVisible, currentPage);
                pages = pages.filter(num => num > 0)

                // console.log('current: ', currentPage, pages);

                buttons.forEach((updateButton, button) => updateButton(button));

            };

            this.render = () => {
                let container = document.querySelector('.paginateContainer');
                if(container) {
                    container.innerHTML = '';
                    paginationButtonsContainer.appendChild(fragment);
                    container.appendChild(paginationButtonsContainer);
                }

            };
        }
    };
  
    
  

return (
    <div className='paginateContainer'>
            <div className='paginateParent pagination-buttons' />
        </div>
    )
    
}

export default Paginate;