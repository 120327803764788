
function createEnum(values) {
    const enumObject = {};
    for (const val of values) {
      enumObject[val] = val;
    }
    return Object.freeze(enumObject);
  }

  let directions = createEnum(['Top', 'Bottom', 'Left', 'Right'])

export default function addToolTip(element, tooltiptext, direction="Bottom") {

    element.classList.add('tooltip');
    let textElement = document.createElement('span');
    textElement.classList.add(`tooltiptext-${direction}`)
    textElement.classList.add('tooltiptext');
    textElement.textContent = tooltiptext
    element.appendChild(textElement)
    return element
}