import React from "react"
import './index.css'
import errorImg from '../../images/404Bot-removebg-preview.png'

export default function ErrorPage() {

    return (
        <div className="ErrorComponentContainer flexCenterErrorPage">
            <div className='ErrorComponentContentContainer flexCenterErrorPage'>
                <p className="errorTitle1">Oops...</p>
                <p className="errorTitle">Page Not Found...</p>
                <img src={errorImg} alt="the one"></img>
                <p className="errorText">We're not picking up what your putting down</p>
                <button className="aloBtn" onClick={() => {window.location.href =window.location.origin }}>Go Home</button>

            </div>
        </div>
    )
}