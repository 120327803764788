import React from "react";
import { useNavigate, Navigate} from "react-router-dom";

let Callback = () => {
    const navigate = useNavigate()
    
    let path = localStorage.getItem("pathname") !== '/callback' ? localStorage.getItem("pathname") : "/"


    // window.location.href = window.location.origin + path
    navigate(localStorage.getItem("pathname"))
    
}

export default Callback;