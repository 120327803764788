import "./index.css"


function changeHandle(e) {
    let btn = document.querySelector(".isLockedBtn")
    let inp = e.target.value;

    if(inp === "") {
        btn.disabled = true;
        btn.style.cursor = "not-allowed"

    } else {
        btn.disabled = false;
        btn.style.cursor = "pointer"
    }
}


export default function isLocked(submitCallback) {
    let btn = document.querySelector(".isLockedBtn")
    let input = document.querySelector("#qrLockPass")
    let errorEl = document.querySelector(".qrpasswordErr")

    input && input.addEventListener("incorrect", (e) => {
        errorEl.style.display = "block";
        input.value = ""
        setTimeout(() => {
            errorEl.style.display = "none";
        }, 2000)

    })

    if(btn) {
        btn.disabled = true;
        btn.style.cursor = "not-allowed"
    }

    if(input) input.focus()
    
    function submitHandle() {
        let input = document.querySelector("#qrLockPass")
        submitCallback(input.value, () => {
            const event = new CustomEvent("incorrect", { detail: "Incorrect Password for QR View" });
            input.dispatchEvent(event)
        })
    }
    
    
    return (
        <div className="islockedParent">
           <p className="isLockedTitle">Lovedone Locked</p>
           <div className="isLockedChild">
                <label className="passtext">Password</label>
                <input type="text" id="qrLockPass" maxLength="20" onChange={changeHandle}/>
           </div>
                <p className="qrpasswordErr" >Incorrect password</p>
           <button className="isLockedBtn" onClick={() => {submitHandle()}}>Submit</button>
        </div>
    )
}