import React, {useState} from 'react';
import { moralityQuote, randomQuote } from './otherutils/quotesArrays';

class Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFact: false,
            currentQuote: "",
            intervalId : 0,
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId)
    }
    
    componentDidMount() {
        this.setState({currentQuote: randomQuote()})
        let intervalID = setInterval(()=> {
            
            if(!this.state.isFact) {
                this.setState({isFact : !this.state.isFact, currentQuote: moralityQuote()})
            }
            else {
                this.setState({isFact : !this.state.isFact, currentQuote: randomQuote()})
            }
        }, 4000)
        this.setState({intervalId: intervalID})
    }

    render() {
        return (
            <div className="loaderCont" style={{display: 'block'}} >
                {/* <div className="ball1"></div>
                <div className="ball2"></div> */}
                <p className="loadText">{this.state.currentQuote}</p>


                <div class="loader">
                <div class="inner one"></div>
                <div class="inner two"></div>
                <div class="inner three"></div>
                </div>


            </div>
        )
    }
}

export {Loader}