import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { CallLogin } from "./loginCaller";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  

  
  return <button className='link' onClick={() => {
    localStorage.setItem("pathname", window.location.pathname)
  loginWithRedirect({
    appState: {
      returnTo: window.location.pathname,
    },
  })
  }}>Log In</button>;
};

export default LoginButton;