import { Auth0Provider } from "@auth0/auth0-react";
import Auth0ProviderWithNavigate from "./components/auth0Provider/Auth0Provider";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import { useNavigate } from "react-router-dom";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
    <Auth0ProviderWithNavigate>
            <App />
      </Auth0ProviderWithNavigate> 
    </BrowserRouter>
  // </React.StrictMode>
);
