import React from "react";
import {useEffect} from 'react';

import './additionalinfoForm.css';

let AdditionalInfoForm = () => {
    
    useEffect(() => {
        let fileUploader = document.querySelector('#file');
        let imageView = document.querySelector('.imageView');

        fileUploader.addEventListener('change', (e) => {
            imageView.innerHTML = '';
            let imgTg = (data) => {
               return `<img class='uploadImgContainer1' src=${data} alt='uploaded'></img>`
            }

            let files = e.target.files;
            // console.log(files[0])
            for(let i = 0; i < files.length; i++) {
                
                let test = URL.createObjectURL(files[i]);
                imageView.innerHTML += imgTg(test)
                // console.log(imageView.lastChild)
                // while(imageView.children.length >= 4) {
                //     imageView.removeChild(imageView.lastChild)
                // }
            }
        })
    }, [])

    function privateCheckHandle(e) {
        let checked = e.target.checked
        let el = document.querySelector(".qrLockContainer")
        if(checked) {
            el.style.display = 'flex'
        } else {
            el.style.display = 'none'
        }
    }
    

    return (
        <div>
            <div className='flexCol pad1'>
                <label>Images</label>             
                <input className='imageInput' id="file" type="file" name="fileToUpload" accept="image/jpeg,image/gif,image/png,image/x-eps" multiple></input>
                <div className='imageView'></div>
            </div>
            <div className='flexCol pad1'>
                <p>If you have videos of the loved one that you would like to be kept.</p>
                <p>Create a gmail account and upload these videos to the youtube account.</p>
                <p style={{marginBottom: '1rem'}}>Paste the youtube account link here for others to view the videos.</p>
                <label>Youtube Account Link:</label>
                <input className='youtube' placeholder='https://www.youtube.com/macmiller' type="text" />
            </div>
            <div className='flexCol pad1'>
                <p>Social Media Usernames</p>
                <label>Facebook</label>
                <input className='facebook' placeholder='https://www.facebook.com/MacMiller/' type="text" />
                <label>Instagram</label>
                <input className='insta' placeholder='https://www.instagram.com/macmiller/' type="text" />
                <label>Twitter</label>
                <input className='twitter' placeholder='https://twitter.com/MacMiller' type="text" />
            </div>
            <div className='pad1'>
                <label>Additional Documents - not including images and videos</label>
                <input type="file" name="docs" id="additionalDocs" accept='.docx, .txt, .pdf' multiple/>
            </div>
            <div className=" checkboxContainer">
                <label className=''>Do you want this person to be private?</label>
                <input className='pCheckbox' style={{marginLeft: '1rem'}} type="checkbox" onChange={(e) => privateCheckHandle(e)} name="public" id="public" />
            </div>
            <div className="qrLockContainer" >
                <label className="">Password for the private QR?</label><input className="qrLock" style={{marginLeft: "1rem"}} type="text" name="pass" id="qrPass"/>
            </div>
            <div className="submitBtnCont">
                <input className='aloBtn submitBtnInfo' type="submit" value='Submit' disabled/>
            </div>
        </div>
        
    )
}

export default AdditionalInfoForm;