import React from 'react';
import './about.css';

class Qbox extends React.Component {
    constructor(props) {
        super();

        this.state = {
            question: props.question,
            paragraphs: props.paragraphs,
            open: false
        }
        this.clickHandle = this.clickHandle.bind(this);
    }

    clickHandle() {
        
        this.setState({
            open: !this.state.open
        })
        // console.log("click", this.state.open)
    }



    render() {
        
        return (
            <div className='questionBox  Qborder' style={{border: `${this.state.open ? "2px solid black" : "none"}`, borderRadius:`25px`, marginBottom: `${this.state.open ? "1.5rem" : '0px' }`}} onClick={() => { this.clickHandle()}}>
                <h3 className='questionFontSize alignCenter' style={{marginBottom: '1rem', textAlign:`${this.state.open ? "center" : "left"}`}}>{this.state.question}</h3>
                <div className="centerFlex" style={{display: `${this.state.open ? "block" : "none"}`}}>
                            {this.state.paragraphs && this.state.paragraphs.map((paragraph, i) => {
                                return <div key={i}><p  className='textSize pargraph'>{paragraph}</p><br/></div>
                            })}
    
                </div>
            </div>
        )
    }
}

export default Qbox;