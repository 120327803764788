import React, { useEffect } from "react";
import './adderForm.css';
import {useState} from 'react';
import axios from 'axios';
import { MapModal } from "../Modal/Modal";
import { FaMapMarkedAlt } from "react-icons/fa";


let AdderForm = ({getCoords}) => {
    let [currSiblings, setCurrSiblings] = useState(0);
    let [currKids, setCurrKids] = useState(0);
    let [mapChooseType, setMapChooseType] = useState(null)
    let [birthObj, setBirthObj] = useState({})
    let [deathObj, setDeathObj] = useState({})

    useEffect(() => {
        if(birthObj.place && deathObj.place )getCoords({birthObj, deathObj})
    }, [birthObj, deathObj, getCoords])

    let siblingsInputHandler = (e, type) => {
        if(type === 'sibling') {    
            const numSiblings = parseInt(e.target.value);
            setCurrSiblings(numSiblings);
        } else if(type === 'children') {
            const numChildren = parseInt(e.target.value);
            setCurrKids(numChildren);
        }
        
    }

    // takes a integer and returns or removes extra form fields 
    // for siblings and kids info input
    let ImmediateFamilyFormAdderJSX = (type) => {
        const siblingFields = [];
        for (let i = 0; i < (type === 'sibling' ? currSiblings : currKids); i++) {
            siblingFields.push(
                <div className="inputRowContainer" key={i}>
                <label style={{textAlign: "end"}} htmlFor={`${type}-name-${i}`}>{type === 'sibling'? "Sibling" : "Child"} Name:</label>
                <input type="text" id={`${type}-name-${i}`} name={`${type}-name-${i}`} />
                </div>
            );
        }
        return siblingFields;
    }

    function bio() {
        return (
            <div style={{'margin': '1rem', 'display': 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                <textarea className="textAreaInput" onChange={(e) => {
                        e.target.value.replace('\n', '<br/>')
                    }} required maxLength='1000' style={{resize: 'none'}}></textarea>
            </div>
        )
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // Name checkers

    let checkingForFullname = (str) => {
        let names = str.trim().split(' ').filter((word) => word !== '' ?  word : null);
        // if there is a space and after the space is a string take that as user having a lastname
        if(!names[1] || !names[1][0]) return false
        else return true
    }

    let nameInputErrorHandle = (el, notError, elementClass) => {
        let inputEl = document.querySelector(`.${elementClass}`);
        inputEl.style.display= !notError ? 'block': 'none';
        inputEl.textContent = "Must Input First and last name."
        el.target.style.borderColor = !notError ? 'red' : 'green';

        let submitBtnEl = document.querySelector('.submitBtnInfo');
        submitBtnEl.disabled = notError ? false : true;
        submitBtnEl.style.background = notError ? 'orange': 'grey';
    }

    let nameCheckHandler = (e, type) => {
        let check = checkingForFullname(e.target.value)
        nameInputErrorHandle(e, check, type)

    }
    // name checker END
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // City Finder
    let cancelToken;
    const handleCitySearchChange = async (e) => {
        if(e.target.value === birthObj.place) return 
        const searchTerm = e.target.value;

        function showResults(val) {
            let res = document.querySelector(".optionBirth");
            res.value=val
        }

        //Check if there are any previous pending requests
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source();
        try {
            const results = await axios.get(
                `https://api.api-ninjas.com/v1/city?name=${searchTerm}`,
                { cancelToken: cancelToken.token, headers: { 'X-Api-Key': "tHnQjlr/P2NrPAIso3u9Pw==OsZJoe2qBa0selvI", 'Content-Type': 'application/json'}}  //Pass the cancel token to the current request
            );
            if(typeof results.data[0] === typeof undefined) {
                // when auto complete clicked it does not find a place because of str concat so leave empty to remove error from log
            } else {
                showResults(results.data[0].name + ", " + results.data[0].country)        // displayOption(results.data)
            }
        } catch (error) {
            if(error.code === "ERR_CANCELED") {
                // axios cancel token logs here
            } else {
                console.error(error);
            }
    }
}
// City finder END
// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    let mapChoose = (e, type="death") => {
        e.preventDefault();
        if(type === "death") {
            setMapChooseType("death")
        } else if(type === 'birth') {
            setMapChooseType("birth")
        }
    }

    let mapCoordProccessor = (isOK, obj) =>{
        if(!isOK) return 

        console.log(obj)
        setMapChooseType(null)
        let {type, lng, lat, place} = obj

        if(type === "death") {
            setDeathObj({lat, lng, place})
        } else if(type === 'birth') {
            setBirthObj({lat, lng, place})
        }
    }

    return (
        <div className='adderForm' >
                <p className='LONameError' style={{display: 'none', color: 'red'}}></p>
            <div className="inputRowContainer">
                <label class="requiredRedStar" htmlFor='Name'>Full Name Of Loved One:</label>
                <input className='lovedOnesNameInput required' name='Name' type="text" placeholder='Mac Miller' onChange={(e) => {nameCheckHandler(e, 'LONameError')}} required/>
            </div>
            <div className="inputRowContainer">
                <label class="requiredRedStar" htmlFor="DOB">Date of Birth:</label>
                <input type="date" required/>
            </div>
            <div className="inputRowContainer">
                <label class="requiredRedStar" htmlFor="DOD">Date Of Death:</label>
                <input type="date" required/>
            </div>
            <div className="inputRowContainer bioInput">
                <label class="requiredRedStar">Loved One Bio</label>
                {bio()}
            </div>
            <div className="inputRowContainer">
                <label class="requiredRedStar">Place Of Birth:</label>
                
                <input className='birthPlace' type="text" disabled list="cityname" value={birthObj.place} placeholder="Pittsburgh, US" required/> 
                <datalist id="cityname">
                    <option className="optionBirth" value="Auckland, NZ"/>
                </datalist>
            </div>
            <div className="MapButtonContainer">
                <button className="MapButtons aloBtn" onClick={(e)=> mapChoose(e, 'birth')}>Choose Birth Place <FaMapMarkedAlt size="2.5em"/></button>
            </div>

            <div className="inputRowContainer">
                <label class="requiredRedStar">Place Of Burial:</label>
                <input id='placeOfRest' type='text' disabled value={deathObj.place} list="cityname" placeholder="Pittsburgh, US" required></input>
            </div>
            <div className="MapButtonContainer">
                <button className="MapButtons aloBtn" onClick={(e)=> mapChoose(e)}>Choose Burial Place <FaMapMarkedAlt size="2.5em"/></button>
            </div>
            {mapChooseType !== null && <MapModal callback={mapCoordProccessor} type={mapChooseType}/>}
            <p className='MotherNameError' style={{display: 'none', color: 'red'}}/>
            <div className="inputRowContainer">
                <label class="requiredRedStar">Mother's Name:</label>
                <input className='mothersNameInput' type="text" placeholder='Karen Meyers' onChange={(e) => {nameCheckHandler(e, 'MotherNameError')}} required/>
            </div>
            <p className='FatherError' style={{display: 'none', color: 'red'}}/>
            <div className="inputRowContainer">
                <label class="requiredRedStar">Father's Name:</label>
                <input className='fathersNameInput' type="text" placeholder='Mark McCormick' onChange={(e) => {nameCheckHandler(e, 'FatherError')}} required/>
            </div>
            <br/>
            <div className="inputRowContainer">
                <label htmlFor="num-sibling">Number of siblings:</label>
                <input type="number" id="num-sibling" name="num-sibling" min="0" max="15" defaultValue={0} onChange={(e) => {siblingsInputHandler(e, 'sibling')}} />
            </div>
                {ImmediateFamilyFormAdderJSX('sibling')}
            <div className="inputRowContainer">
                <label htmlFor="num-children">Number of children:</label>
                <input type="number" id="num-children" name="num-children" min="0" max="15" defaultValue={0} onChange={(e) => {siblingsInputHandler(e, 'children')}} />
            </div>
                {ImmediateFamilyFormAdderJSX('children')}
        </div>
    );
}

export default AdderForm;