import {
  APIProvider,
  AdvancedMarker,
  AdvancedMarkerElement,
  Pin,
  Map,
  Marker,
  useMapsLibrary,
  useAdvancedMarkerRef,
  InfoWindow,
  latLngEquals
} from "@vis.gl/react-google-maps";
import React, {useRef, useEffect, useState} from "react";

function MarkerComponent({lat="-43.825723754816664", lng="171.825454242713", options={}, color="red", meta="", placeName}) {
    const [infowindowOpen, setInfowindowOpen] = useState(false);
    const [markerRef, marker] = useAdvancedMarkerRef();
    return (
      <>
        <AdvancedMarker
          ref={markerRef}
          key={"ImageLocation"}
          position={{ lat: Number(lat), lng: Number(lng) }}
          onClick={() => setInfowindowOpen(true)}
          {...options}
        >
          <Pin className="marker_1" background={color} glyphColor={"#000"} borderColor={"#000"} />
        </AdvancedMarker>
        {infowindowOpen && <InfoWindow
          anchor={marker}
          maxWidth={200}
          onCloseClick={() => setInfowindowOpen(false)}>
          <span style={{fontWeight: "bold", color: "gray", textDecoration:"underline"}}>{meta.key}</span><br/>
          {placeName}
        </InfoWindow>}
      </>
    );
};

// if you pass metadata then considered to be a map showing 2 locations only with no click
class MapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: props.lat,
      lng: props.lng,
      callback: props.callback || null,
      markers: [{ type: "image", lat: props.lat, lng: props.lng }],
      clickCoords: {},
      mapData: props.mapData || null,
      currentCenter:{lat: -43.825723754816664, lng: 171.825454242713},
      mapKey: 1
      
    };
  }

  componentDidMount() {
    if (this.state.mapData && this.state.mapData.birthPlace && this.state.mapData !== null) {
      let data = this.state.mapData.birthPlace
      this.setState({currentCenter: {lat: Number(data.lat), lng: Number(data.lng)}})
    } else if(this.state.lat && this.state.lng){
      this.setState({currentCenter: {lat: Number(this.state.lat), lng: Number(this.state.lng)}})
    } else {
      this.setState({currentCenter: {lat: -43.825723754816664, lng: 171.825454242713}})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.mapData && (this.props.mapData.currentCenter !== this.state.mapData.currentCenter)) {
      this.setState({mapData: {
        ...this.state.mapData,
        currentCenter: this.props.mapData.currentCenter
      }})
      let buried = this.state.mapData.burialPlace;
      let born = this.state.mapData.birthPlace;
      // this.setState({mapData: {
      //   ...this.state.mapData,
      //   currentCenter: 
      // }})
      
      if(this.props.mapData.currentCenter === "born" && this.state.currentCenter.lat !== born.lat) {
        this.setState({
          currentCenter: {lat: Number(born.lat), lng: Number(born.lng)},
          mapKey: this.state.mapKey +1
        })

      }else if (this.props.mapData.currentCenter === "buried" && this.state.currentCenter.lat !== buried.lat) {
        this.setState({
          currentCenter: {lat: Number(buried.lat), lng: Number(buried.lng)},
          mapKey: this.state.mapKey +1

        })
      }
    }
  }

  mapChangeHandle = (e) => {
  };

  clickHandle = (e) => {
    if(this.state.mapData && this.state.mapData.burialPlace) return
    let { lat, lng } = e.detail.latLng;
    this.setState({
      clickCoords: { lat, lng },
    });
    this.state.callback({lat, lng})
  };

  addMarker = (type, lat, lng) => {
    this.setState({ markers: [...this.state.markers, { type, lat, lng }] });
  };

  renderMarkers = () => {


    return this.state.markers.map((m) => {
      return <MarkerComponent lat={m.lat} lng={m.lng} />;
    });
  };

  renderPersonPlaces = () => {
    // renders the coords when a person is viewing loved ones locations on map
    let keys = Object.keys(this.state.mapData)
    return keys.map((key) => {
      if(key === "currentCenter") return null 
      let {lat, lng, placeName} = this.state.mapData[key]
      return <MarkerComponent lat={Number(lat)} lng={Number(lng)} placeName={placeName} options={{title: "market", gmpClickable:true}} color="lightgreen" meta={{key: key === "burialPlace" ? "Buried": "Born"}}/>;
    });
  };

  render() {
    return (
      <div id="map">
        <APIProvider
          apiKey={process.env.REACT_APP_GMAP_TOKEN}
        >
          <Map
            defaultZoom={(this.state.lat === null) ? 5 : (this.state.mapData && this.state.mapData.burialPlace) ? 10 :13}
            mapId={process.env.REACT_APP_MAP_ID}
            defaultCenter={ this.state.currentCenter}
            onCenterChanged={this.mapChangeHandle}
            onClick={this.clickHandle}
            key={this.state.mapKey}
          >
            {(this.state.lat && this.state.lat !== 0) ? this.renderMarkers(): null}
            {(this.state.mapData) ? this.renderPersonPlaces(): null}
            {(this.state.clickCoords.lat ) && (
              <AdvancedMarker
                key={"ImageLocation"}
                position={{
                  lat: this.state.clickCoords.lat,
                  lng: this.state.clickCoords.lng,
                }}
                gmpClickable={true}
                onClick={(e) => {
                    if(this.state.callback) this.state.callback(this.state.clickCoords)
                }}
              >
                <Pin
                  background={"orange"}
                  glyphColor={"#000"}
                  borderColor={"#000"}
                />
              </AdvancedMarker>
            )}
          </Map>
        </APIProvider>
      </div>
    );
  }
}

export default MapComponent;
