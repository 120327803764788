import './support.css';
import {postAuthData} from '../../utils/axiosCalls';
import {useNavigate} from 'react-router-dom'
import {Loader} from '../../utils/loader'

import React, {useState} from 'react';
import { InfoModal } from '../Modal/Modal';

export default function Support() {
    let [sent, setSent] = useState(false);
    let [empty, setEmpty] = useState(false);
    let [loading, setLoading] = useState(false);
    let [emailSendStatus, setEmailSendStatus] = useState('not sent')
    const navigate = useNavigate()

    let checkEmail = (email) => {
        let valid = email.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi);
        let el = document.querySelector('.formError');
        el.style.display = `${valid ? "none" : "block"}`;
    };

    let clickHandler = (e) => {
        setLoading(true)
        e.preventDefault();
        let inputs = document.querySelectorAll('.supportInput');
        let supportEmail = {};
        inputs.forEach((input, i) => {
            switch (i) {
                case 0:
                    supportEmail.name = input.value;
                    break;
                case 1:
                    supportEmail.title = input.value;
                    break;
                case 2:
                    supportEmail.message = input.value;
                    break;
                default:
                    break;
            }
        })
        let emptyValues = false
        Object.keys(supportEmail).forEach(key => {
            if(supportEmail[key] === "") {
                setEmpty(true)
                return 
            } 
        })
        postAuthData(`/support/message`, {supportEmail}, null, (res) => {
            console.log(res)

            if(res.data.status === 'sent') {
                setLoading(false)
                setSent(true)
                setEmailSendStatus('success')
            } else {
                setLoading(false)
                setEmailSendStatus('failed')
                console.log(res)
            }
        })
        
    }


    let formView = () => {
        return (
            <div className="content">
                <div className="textHolder">
                    <p>Use the form below to send us an email with your issues.</p>
                </div>
                <div className='formHolder'>
                    <form id='supportForm'>
                        <div className='inputCont'>
                            <div className='inputHolder'>
                                <label>Name:</label>
                                <input className= 'supportInput' type="text" required></input>
                            </div>
                            <div className='inputHolder'>
                                <label>Issue Title:</label>
                                <input className= 'supportInput' type="text" required></input>
                            </div>
                        </div>
                        <label htmlFor='message'>Message:</label>
                        <textarea className= 'supportInput' type="text" rows='10' cols="10" name='message' required></textarea>
                        <br/>
                        <div className='supportBtn'>
                            <button type="submit" className="helpSubmit aloBtn" onClick={(e) => {clickHandler(e)}} >Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    let success = () => {
        return (
            <>
                <p>Successfully sent email to the support team.</p>
                <br/>
                <p>You should receive a email shortly verifying that the email was successfuly received.</p>
                <br/>
                <p>Redirecting you to the Home Page</p>
            </>
        )
    }

    let failed = () => {
        return (
            <>
                <p>Failed to send email to the support team.</p>
                <br/>
                <p>Please Try again</p>
                <br/>
            </>
        )
    }

    return (
        <div className="supportContainer">
            {empty && <InfoModal title='Error' text='You must fill all fields in order to send the request' callback={() => setEmpty(false)}/>}
            {!empty && !sent && formView()}
            {(!empty && sent && emailSendStatus === 'success') && <InfoModal title='Success!' text={success()} callback={(bool) => bool && navigate('/profile')}/>}
            {(!empty && sent && emailSendStatus === 'failed') && <InfoModal title='Failed!' text={failed()} callback={(bool) => setSent(false)}/>}
            {loading && <Loader />}
        </div>
    )
}