import React from "react";
import './about.css';
import { useAuth0 } from "@auth0/auth0-react";
import Qbox from './aboutQuestionContainer.js';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';


let About = () => {
    const {isAuthenticated } = useAuth0();
    let navigate = useNavigate();

    let qbox1 = [
        `This application was thought up in may of 2022, after a loved one of mine had passed away and I was 
        left with many digital items that was once his, in fear of losing these digital items I created this app 
        to store the items and allow others to also view these items in a central digital location.`,
        'I wanted to give others the opportunity to use the application to store their favoured memories with their loved ones and give a modern touch to the way we visit our loved ones.'
    ]

    let qbox2 = [
        "The application saves the data and returns a qr code with a link to the site allowing the user that is scanning to view the loved ones data in a better format.", 
        "The qr code can be copied and printed, engraved in a headstone or other object that may not be digital."
    ]
    
    let qbox3 = ['Price: $34.99' , 'This pays for the service and storage of the items that you will provide to the application.', 'A QR code is generated automatically for your loved one. You may find an engraver or do with it as you please.',  'If you want the convinience of us procuring a metal plate engraved with the QR code we can have that done.']

    let qbox4 = ['In order to request for support or inform us of any bugs:','Log in and click the email us button that appears on this page to the left or please send an email to alotech.help@gmail.com']
    
    let qbox5 = ['With this app you are able to store general personal information related to the loved one such as place if birth/death, sibling and kids, a set of images, link to a youtube account which provides free video storage, links to the loved ones social media accounts.', '']
    
    

    useEffect(() => {
        let removeAllEventListeners = (el) => {
            const oldBtnElement = document.getElementsByClassName(el)[0];
            const newBtnElement = oldBtnElement.cloneNode(true);
            oldBtnElement.parentNode.replaceChild(newBtnElement, oldBtnElement);
            // console.log("Removed all listeners")
        }
        removeAllEventListeners('helpBtn')
    
        let help = document.querySelector('.helpBtn');
        let purchaseBtn = document.querySelector('.purchase');

        let helpCont = document.querySelector('.helpBtnCont');

        purchaseBtn.addEventListener('click', () => {
            navigate("/purchase")
        })
        
        if(isAuthenticated) {
            help.classList.remove('helpBtnDisabled')
        }

        if(help) {
            help.addEventListener('click', () => {
            
                if(isAuthenticated) {
                    navigate("/support")
                } 
            })
        }
    
        if(!isAuthenticated && help){
            help.addEventListener('mouseover', () => {
                if (!isAuthenticated) {
                    let tooltip = document.createElement('p');
                    tooltip.classList.add('helpText');
                    tooltip.textContent = 'Please login to send email.'
                    tooltip.style.background = 'whitesmoke';
                    tooltip.style.textAlign = 'center';
                    helpCont.appendChild(tooltip);
                }
            })
        }

        (!isAuthenticated && help) && help.addEventListener('mouseout', () => {
            !isAuthenticated && helpCont.removeChild(helpCont.lastChild)
        })
    }, [isAuthenticated, navigate]);
    
   
    

    return (
        <div className="aboutParent">
                <div className='QBoxParent'>
                    <p style={{marginBottom: '2rem', visibility: "hidden"}}>About Page</p>
                    <div className='helpBtnCont'>
                        <button className='helpBtn aloBtn helpBtnDisabled' to="/support" >Support</button>
                        <button className='aloBtn purchase' to="/purchase" >Pricing</button>
                    </div>
                    <div className='QboxContainer'>
                        <Qbox question="Why was this created?" paragraphs={qbox1} click={true}/>
                        <Qbox question="What can I use this for?" paragraphs={qbox2}/>
                        <Qbox question="How much does storage for a loved one cost?" paragraphs={qbox3} />
                        <Qbox question="How do I request support?" paragraphs={qbox4} />
                        <Qbox question="What can I store using this application?" paragraphs={qbox5} />

                    </div>
                </div>
                <div className='helpBtnCont'></div>

            {/* <button className='helpBtn'>Support</button> */}
        </div>

    )
}

export default About;