import React, {useEffect} from 'react';
import { Navigate } from "react-router-dom";
import './home.css';
import fatherSon from '../../images/fatherSon.jpg';
import QR from '../../images/purchaseImages/QR Tag.png';
import { Loader } from '../../utils/loader';


let Home = () => {

    let QRClickHandler = () => {
        window.open('https://a-lo.life/qr/64ee89f562894d0e856d934f', '_blank')
    }

    let pricingClickHandler = () => {
        window.open('https://a-lo.life/purchase', '_blank')
    }

    let geoClickHandler = () => {
        window.open(window.origin + '/geolocator')
    }

    return (
        <div className="homeBG">
            <div className='homeContainer'>
                
                <div className='dualCol row'>
                    <div className='homeText leftTxt pad1'>
                         
                        <h2 className='homeCardTitle' style={{textAlign: 'center', marginBottom:'1rem', marginTop: '1rem'}}>A-Lo safeguards your special moments forever.</h2>
{/*                        
                       <p>Welcome to A-Lo, where we believe that every person's story deserves to be told and shared with the world. When we lose a loved one, their digital footprint still surrounds us, reminding us of them.<br/><br/> That's why we've created a new way to remember our loved ones and preserve their digital legacy.
With A-Lo, you can create a personalized profile for your departed loved ones, storing their memories, photos, videos, and personal stories in one convenient digital place.<br/><br/> Our unique QR code feature allows others to access and view your loved one's profile, whether it's on their grave stone or in another special place.
Imagine being able to share your loved one's story with generations to come, preserving their memory for years to come.<br/><br/>Join us on A-Lo today and start sharing your loved one's life story with the world. Let's celebrate their legacy together and ensure that their memory lasts forever.</p>
                        */}
                        <p style={{marginBottom:'1rem'}}>
                        Step into A-Lo, where memories become timeless treasures. 
                        <br/><br/>
                        
                        In a world where everything is scattered across different sites, having one place to store all your loved ones data is invaluable. 
                        No more risk of a single point of loss; now every family member has access to the shared treasure trove. 
                        
                        <br/><br/>
                        As time goes on, how valuable would it be to have a portal to the past that grows more 
                        precious every day? 
                        <br/><br/>
                        Join A-Lo now and start a journey where memories gain lasting significance.
                        </p>
                    </div>
                    <div className='imgContainerHome imgRight'>
                        <div className='fatherSonBG'/>
                    </div>
                </div>
                <div className='dualCol revRow'>
                    <div className='homeText leftTxt pad1'>
                        <h2 className='homeCardTitle' style={{textAlign: 'center', marginBottom:'1rem', marginTop: '1rem'}}>Your Loved One's Story Deserves to be Told: Preserve it with A-Lo</h2>
                       
                       <p style={{marginBottom:'1rem', marginTop: '1rem'}}>The QR code generated, will point to the landing page of your loved one. This QR code can be engraved in metal and sent to your address for $49.99.</p>
                       <p>The metal plate can be placed on a gravestone, curbside to remember where they once lived, a public bench if one was purchased for your loved one, and anywhere else your loved one's memories will remain.</p>
                       <p style={{marginTop: '1rem', marginBottom: '1rem'}}>The landing page of your loved one contains information such as:</p>
                        <div className='listContainer'>
                            <ul>
                                <li>Personal Information</li>
                                <li>Siblings / Kids</li>
                                <li>Images</li>
                                <li>Documents</li>
                                <li>Social Media Links</li>
                                <li>Visitor Comments including images</li>
                                <li>Birthday Comments with images</li>
                                <li>Ability to email the creator of the loved one</li>
                            </ul>
                        </div>
                       <div className='dualBtnsHome'>
                        <p className='homeBtns' onClick={QRClickHandler}>View a loved ones Landing page</p>
                        <p className='homeBtns' onClick={pricingClickHandler}>Check out our pricing</p>
                       </div>
                       <div className='dualBtnsHome'>
                        <p className='homeBtns' style={{width: "80%"}} onClick={() => {
                            geoClickHandler()
                        }}>Check out our image Geolocator</p>
                        </div>

                       
                    </div>
                    <div className='imgContainerHome imgLeft'>
                        <div className='qrBG'>

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;